import { Container, Form } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import InputTexto from "../../components/Input/Texto";
import { useState } from "react";
import Botoes from "./components/Botoes";
import { useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import InputTextoMask from "../../components/Input/Mask";

const AdicionarCliente = () => {
  const { http,logout } = Auth();
  const [cliente, setCliente] = useState({
    
  });
  const navigate = useNavigate();

  const hundleSubmit = async (e) => {
    e.preventDefault()
    try {
      await http
      .post("adicionar-cliente", cliente)
      .then((response) => {
        navigate("/clientes")
      })
    } catch (error) {
      const { statusText } = error.response
      if (statusText==="Unauthenticated") logout()
    }
  }

  return (
    <Container>
      <Titulo texto={"Adicionar Cliente"} />

      <Form onSubmit={hundleSubmit}>
        <InputTexto
          label="Fantasia"
          change={setCliente}
          tipo={"nome"}
          objeto={cliente}
          required
        />
        <InputTexto
          label="R. Social"
          change={setCliente}
          tipo={"pj_razao"}
          objeto={cliente}
          required
        />
        <InputTextoMask
          label="CNPJ"
          change={setCliente}
          tipo={"pj_cnpj"}
          objeto={cliente}
          mask={'00.000.000/0000-00'}
          required
        />
        <InputTexto
          label="I.M."
          change={setCliente}
          tipo={"pj_im"}
          objeto={cliente}
        />
        <InputTexto
          label="I.E."
          change={setCliente}
          tipo={"pj_ie"}
          objeto={cliente}
        />
        <InputTexto
          label="Suframa"
          change={setCliente}
          tipo={"pj_suframa"}
          objeto={cliente}
        />
        <Botoes />
      </Form>
    </Container>
  );
};

export default AdicionarCliente;
