import { Container, Table } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import { useCallback, useEffect, useState } from "react";
import Auth from "../../helpers/Auth";
import BotaoRemove from "../../components/Botao/Remove";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Pagina from "../../components/Pagina";
import Swal from "sweetalert2";

const Clientes = () => {
  const [clientes, setClientes] = useState();
  const { http, logout } = Auth();
  const [loading, setLoading] = useState(true);
  const [infos, setInfos] = useState({ pages: 1, total: 0 });
  const [page, setPage] = useState(1);
  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);

  const getClientes = async () => {
    setLoading(true);
    try {
      const res = await http.get("clientes", { params: { page, qtd: 10 } });
      setClientes(res.data.data);
      setInfos({
        pages:res.data.totalPaginas,
        total: res.data.totalItens
      });
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: 'Algum erro com o servidor aconteceu. Tente novamente!',
          confirmButtonColor: "#dc3545",
        });
      }
    }
  };

  const removeCliente = async (id) => {
    try {
      await http.put(`deletar-cliente/${id}`);
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: 'Algum erro com o servidor aconteceu. Tente novamente!',
          confirmButtonColor: "#dc3545",
        });
      }
    }
  };

  function formatarCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }

  useEffect(() => {
    getClientes();
  }, [page]);

  if (loading) return <Loading />;
  else
    return (
      <Container>
        <Titulo texto={"Clientes"} btn />

        <span>Total: {infos.total}</span>

        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Razão</th>
              <th>CNPJ</th>
              <th>IM</th>
              <th>IE</th>
              <th>Suframa</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clientes &&
              clientes.map((cliente, index) => {
                return (
                  <tr key={index}>
                    <td>{cliente.id}</td>
                    <td>
                      <Link
                        to={`/clientes/editar/${cliente.id}`}
                        className="text-decoration-none"
                      >
                        {cliente.nome}
                      </Link>
                    </td>
                    <td>{cliente.pj_razao}</td>
                    <td>{formatarCNPJ(cliente.pj_cnpj)}</td>
                    <td>{cliente.pj_im}</td>
                    <td>{cliente.pj_ie}</td>
                    <td>{cliente.pj_suframa}</td>
                    <td>
                      <BotaoRemove
                        change={setClientes}
                        id={cliente.id}
                        items={clientes}
                        remove={removeCliente}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {infos.pages > 1 && (
          <Pagina
            total={infos.pages}
            current={page}
            onChangePage={handleChangePage}
          />
        )}
      </Container>
    );
};

export default Clientes;
