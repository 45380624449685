import { Container, Tab, Tabs } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import Retorno from "./components/Retorno";
import Importacao from "./components/Importacao";

const Administradores = () => {
  return (
    <Container>
      <div className="my-4 t">
        <Titulo />

        <Tabs
          defaultActiveKey="Retorno"
          id="justify-tab-example"
        >
          <Tab eventKey="Retorno" title="Arquivo de Retorno">
            <Retorno />
          </Tab>
          <Tab eventKey="Importacao" title="Importações">
            <Importacao />
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default Administradores;
