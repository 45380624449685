import { Route, Routes, useNavigate } from "react-router-dom";
import Inicio from "../Pages/Inicio";
import Menu from "../components/Menu";
import Clientes from "../Pages/Clientes";
import AdicionarCliente from "../Pages/Clientes/AdicionarCliente";
import EditarCliente from "../Pages/Clientes/EditarCliente";
import Concursos from "../Pages/Concursos";
import AdicionarConcurso from "../Pages/Concursos/AdicionarConcurso";
import EditarConcurso from "../Pages/Concursos/EditarConcurso";
import { ConcursoProvider } from "../context/ConcursoContext";
import Usuarios from "../Pages/usuarios";
import EditarUsuario from "../Pages/usuarios/Components/EditarUsuario";
import Inscricao from "../Pages/Concursos/Inscricao";
import Administradores from "../Pages/Administradores";
import { useEffect } from "react";
import Auth from "../helpers/Auth";

const AppRoutes = () => {
  
  const navigate = useNavigate()
  const { getToken } = Auth();
  useEffect(() => {
    if (!getToken) {
      return navigate("/login");
    }
  }, []);

  return (
    <>
      <Menu />

      <Routes>
        <Route path="/inicio" element={<Inicio />} />

        <Route path="/clientes" element={<Clientes />} />
        <Route path="/clientes/adicionar" element={<AdicionarCliente/>} />
        <Route path="/clientes/editar/:id" element={<EditarCliente />} />

        <Route path="/concursos" element={<Concursos />} />
        <Route path="/concursos/adicionar" element={<ConcursoProvider><AdicionarConcurso /></ConcursoProvider>} />
        <Route path="/concursos/editar/:id" element={<ConcursoProvider><EditarConcurso /></ConcursoProvider>} />
        <Route path="/concursos/inscricao/:id" element={<ConcursoProvider><Inscricao /></ConcursoProvider>} />

        <Route path="/usuarios" element={<Usuarios/>}/>

        <Route path="/admistradores" element={<Administradores/>}/>

        <Route path="/editar/usuario/:id" element={<EditarUsuario/>} />
      </Routes>
    </>
  );
};

export default AppRoutes;
