import { Container, Form, Tab, Tabs } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import Pessoal from "./components/Pessoal";
import Cargos from "./components/Cargos";
import Botoes from "../../components/Botao/Botoes";
import { useContext } from "react";
import { ConcursoContext } from "../../context/ConcursoContext";
import Auth from "../../helpers/Auth";
import { useNavigate } from "react-router-dom";
import Isencao from "./components/isencao";
import Swal from "sweetalert2";

const AdicionarConcurso = () => {
  const { http, logout } = Auth();
  const navigate = useNavigate();
  const { concurso } = useContext(ConcursoContext);

  const enviarConcursos = async (submit) => {
    const { pessoal, cargos, isencoes } = submit
    pessoal['hora_prova'] = pessoal.hora + "h" + pessoal.minuto
    const request = {pessoal, cargos, isencoes}
    // console.log(request)
    await http.post("adicionar-concurso", request).then((response) => {
      try {
        navigate("/concursos")
      } catch (error) {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: 'Algum erro com o servidor aconteceu. Tente novamente!',
            confirmButtonColor: "#dc3545",
          });
        }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    enviarConcursos(concurso);
  };

  return (
    <Container>
      <Titulo texto={"Adicionar Concurso"} />
      <Form onSubmit={handleSubmit}>
        <Tabs
          defaultActiveKey="Pessoal"
          id="justify-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Pessoal" title="Informações">
            <Pessoal />
          </Tab>
          <Tab eventKey="Cargos" title="Cargos">
            <Cargos />
          </Tab>
          <Tab eventKey="isencao" title="Isenções">
            <Isencao />
          </Tab>
        </Tabs>
        <Botoes path={"/concursos"} />
      </Form>
    </Container>
  );
};

export default AdicionarConcurso;
