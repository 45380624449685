import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Botoes = ({path}) => {

    const navigate = useNavigate()

    const hundleCancelar = () => {
        navigate(path)
    }

  return (

    <div className="w-100 d-flex justify-content-center">  
      <div>
        <Button type="submit" className="me-2 fw-bold">Enviar</Button>
        <Button variant="secondary" type="submit" className="fw-bold" onClick={hundleCancelar} >Cancelar</Button>
      </div>
    </div>

  )
};

export default Botoes;