import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";

const InputTextoMask = ({ label, change , objeto , tipo, ...rest }) => {

  const hundleChange = (e) => {
    // const value = (e.target.value).replace(/\D/g, '')
    change({...objeto, [tipo]: e.target.value})
  }

  return (
    <Form.Group
      as={Row}
      controlId={label}
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end" >
        {label}
      </Form.Label>

      <Col sm={7}>
        <Form.Control
          as={IMaskInput}
          type="text"
          placeholder=""
          value={objeto[tipo]}
          onChange={hundleChange}
          {...rest}
        />
      </Col>
    </Form.Group>
  );
};

export default InputTextoMask;
