import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Titulo = ({ texto, btn, subtexto }) => {

  return (
    <div className="mt-3 mb-4">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="fs-2">{texto}</div>

        {btn && (
          <Button
            as={Link}
            to={"adicionar"}
            variant="primary"
            className="fw-bold"
          >
            Adicionar
          </Button>
        )}
      </div>

      {subtexto && <div className="fs-5">{subtexto}</div>}
    </div>
  )
}

export default Titulo
