import React from "react";
import { Button, Col, Row, Form, Alert, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Input from "./Form/Input";
import { IMaskInput } from "react-imask";
import { useState } from "react";
import Swal from "sweetalert2";
import Check from "./Form/Check";
import Auth from "../../../helpers/Auth";
import Cand from "../../../database/Candidato.json";
import Select from "./Form/Select";

const UserCard = ({ data, setData }) => {
  const { dados_usuario, cpf, email, email_verified_at } = data;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState({});
  const { http, logout, cddt } = Auth();
  const {
    estado_civil,
    etnia,
    nacionalidade,
    nivel_escolar,
    orgaos,
    sexo,
    ufs,
  } = Cand;

  console.log(!email_verified_at);

  function dataHora(value) {
    // 2023-11-10T15:05:31.000000Z
    const data = new Date(value);
    data.setHours(data.getHours() - 4);
    const dataFormatada = data
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("-");
    const horario = data.toISOString().slice(11, 16);
    return dataFormatada + " / " + horario;
    // return dataFormatada;
  }

  function confirmarEditar() {
    Swal.fire({
      text: "Deseja editar as informações do Candidato?",
      icon: "question",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      confirmButtonColor: "#0d6efd",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setDisabled(!disabled);
      }
    });
  }

  const handleConfirm = () => {
    Swal.fire({
      icon: "success",
      text: "Um email de autenticação foi enviado!",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(false);
      }
    });
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
    if (error.response.status >= 500) {
      Swal.fire({
        icon: "error",
        text: "Algum erro com o servidor aconteceu. Tente novamente!",
        confirmButtonText: "Ok",
      });
    } else {
      const { mensagem } = error.response.data;
      Swal.fire({
        icon: "error",
        text: mensagem,
        confirmButtonText: "Ok",
      });
    }
  };

  const hundleSendEmail = async () => {
    setLoading(true);
    await cddt
      .post("reenviar-email-authenticacao", { email })
      .then(() => handleConfirm())
      .catch((error) => handleError(error));
  };

  async function confirmarAlteracoes() {
    const res = await Swal.fire({
      text: "Deseja salvar as alterações sobre o Candidato?",
      icon: "warning",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      confirmButtonColor: "#0d6efd",
      showCancelButton: true,
    });
    return res.isConfirmed;
  }

  function handleChange(e) {
    const { value, name } = e.target;
    setSubmit({ ...submit, [name]: value });
    switch (name) {
      case "email":
        setData({ ...data, email: value });
        break;
      default:
        setData({
          ...data,
          dados_usuario: { ...dados_usuario, [name]: value },
        });
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      const alterar = await confirmarAlteracoes();
      if (!alterar) return;
      const formdata = new FormData();
      Object.entries(submit).map(([key, value]) => {
        formdata.append(key, value);
      });
      formdata.append("cpf", cpf);
      await http.post("editar-dados-candidato", formdata);
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
      }
    } finally {
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="mt-4">
      <Button style={{ width: "100px" }} onClick={confirmarEditar}>
        Editar
      </Button>
      <Button
        style={{ width: "100px" }}
        disabled={disabled}
        className="ms-2"
        variant="outline-primary"
        type="submit"
      >
        Salvar
      </Button>
      <Card className="my-3 border-0" syze="sm">
        <Card.Body>
          <Row>
            <Col md={6}>
              <Input
                name="cpf"
                as={IMaskInput}
                mask="000.000.000-00"
                id="cpf"
                label="CPF:"
                disabled
                value={cpf}
              />

              <Input
                name="nome_completo"
                onChange={handleChange}
                id="nome"
                label="Nome:"
                disabled={disabled}
                value={dados_usuario.nome_completo}
              />

              <Input
                name="email"
                onChange={handleChange}
                id="email"
                label="Email: "
                disabled={disabled}
                value={email}
              />

              {!email_verified_at && (
                <Alert
                  variant="danger"
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <p className="m-0">Email ainda não está autenticado.</p>
                    <p className="m-0">
                      Deseja enviar um email de autenticação?
                    </p>
                  </div>
                  <Button variant="danger" onClick={hundleSendEmail}>
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </Alert>
              )}

              <Input
                id="created_at"
                label="Data e Hora de Criação:"
                value={dataHora(dados_usuario.created_at)}
                disabled
              />
              <Input
                name="data_nascimento"
                onChange={handleChange}
                id="nascimento"
                label="Data de Nascimento:"
                disabled={disabled}
                value={dados_usuario.data_nascimento}
                type="date"
              />

              <Select
                defaultValue={dados_usuario.cor_raca}
                data={etnia}
                id="nome"
                nome="nome"
                label="Cor / Raça:"
                disabled={disabled}
                name="cor_raca"
                onChange={handleChange}
              />

              <Select
                defaultValue={dados_usuario.estado_civil}
                data={estado_civil}
                id="descricao"
                nome="descricao"
                label="Estado Civil:"
                disabled={disabled}
                name="estado_civil"
                onChange={handleChange}
              />

              <Input
                name="nome_mae"
                onChange={handleChange}
                id="mae"
                label="Mãe:"
                disabled={disabled}
                value={dados_usuario.nome_mae}
              />

              <Input
                name="nome_pai"
                onChange={handleChange}
                id="pai"
                label="Pai:"
                disabled={disabled}
                value={dados_usuario.nome_pai ? dados_usuario.nome_pai : ""}
              />

              <Select
                defaultValue={dados_usuario.nacionalidade}
                data={nacionalidade}
                id="descricao"
                nome="descricao"
                label="Nacionalidade:"
                disabled={disabled}
                name="nacionalidade"
                onChange={handleChange}
              />

              <Input
                name="naturalidade"
                onChange={handleChange}
                id="naturalidade"
                label="Naturalidade:"
                disabled={disabled}
                value={dados_usuario.naturalidade}
              />
              <Select
                defaultValue={dados_usuario.sexo}
                data={sexo}
                id="generos"
                nome="generos"
                label="Sexo:"
                disabled={disabled}
                name="sexo"
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <Input
                name="identidade"
                onChange={handleChange}
                id="identidade"
                label={dados_usuario.tipo_identidade + ":" || "Identidade:"}
                disabled={disabled}
                value={dados_usuario.identidade}
              />

              <Select
                defaultValue={dados_usuario.orgao_emissor}
                data={orgaos}
                id="sigla"
                nome="sigla"
                label="Orgão Emissor:"
                disabled={disabled}
                name="orgao_emissor"
                onChange={handleChange}
              />

              <Select
                defaultValue={dados_usuario.uf_orgao_emissor}
                data={ufs}
                id="sigla"
                nome="nome"
                label="UF do Orgão Emissor:"
                disabled={disabled}
                name="uf_orgao_emissor"
                onChange={handleChange}
              />

              <Select
                defaultValue={dados_usuario.nivel_escolaridade}
                data={nivel_escolar}
                id="id"
                nome="nivel"
                label="Escolaridade:"
                disabled={disabled}
                name="nivel_escolaridade"
                onChange={handleChange}
              />

              <div style={{ marginTop: "1.7rem", marginBottom: "1.5rem" }}>
                <strong>Membro do grupo Adventista do Sétimo Dia: </strong>
                <br />
                <Check
                  id="nao"
                  label="Não"
                  name="sabado"
                  value={dados_usuario.sabado}
                  checked={dados_usuario.sabado === 0 ? true : false}
                  disabled={disabled}
                  onChange={() => {
                    setSubmit({ ...submit, sabado: 0 });
                    setData({
                      ...data,
                      dados_usuario: { ...dados_usuario, sabado: 0 },
                    });
                  }}
                />
                <Check
                  id="sim"
                  label="Sim"
                  name="sabado"
                  value={dados_usuario.sabado}
                  checked={dados_usuario.sabado !== 0 ? true : false}
                  disabled={disabled}
                  onChange={() => {
                    setSubmit({ ...submit, sabado: 1 });
                    setData({
                      ...data,
                      dados_usuario: { ...dados_usuario, sabado: 1 },
                    });
                  }}
                />
              </div>

              <Input
                name="cep"
                onChange={handleChange}
                id="cep"
                label="Cep:"
                disabled={disabled}
                value={dados_usuario.cep}
              />

              <Input
                name="endereco"
                onChange={handleChange}
                id="endereco"
                label="Endereço:"
                disabled={disabled}
                value={dados_usuario.endereco}
              />

              <Input
                name="numero"
                onChange={handleChange}
                id="numero"
                label="Número:"
                disabled={disabled}
                value={dados_usuario.numero}
              />

              <Input
                name="complemento"
                onChange={handleChange}
                id="complemento"
                label="Complemento:"
                disabled={disabled}
                value={
                  dados_usuario.complemento ? dados_usuario.complemento : ""
                }
              />

              <Input
                name="bairro"
                onChange={handleChange}
                id="bairro"
                label="Bairro:"
                disabled={disabled}
                value={dados_usuario.bairro}
              />

              <Input
                name="uf"
                onChange={handleChange}
                id="uf"
                label="UF:"
                disabled={disabled}
                value={dados_usuario.uf}
              />

              <Input
                name="municipio"
                onChange={handleChange}
                id="municipio"
                label="Município:"
                disabled={disabled}
                value={dados_usuario.municipio}
              />

              <Input
                name="Telefone1"
                onChange={handleChange}
                id="Telefone1"
                label="Telefone Celular:"
                disabled={disabled}
                value={dados_usuario.Telefone1}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UserCard;
