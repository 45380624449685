import { useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ConcursoContext } from "../../../context/ConcursoContext";

const GrupoCargos = ({ item, index }) => {
  const { cargos, setCargos, hundleMenosCampo } = useContext(ConcursoContext);

  const handleOnChange = (e) => {
    const novoCargos = cargos.map((cargo, i) => {
      if (i !== index) {
        return cargo;
      } else {
        return { ...cargo, [e.target.name]: e.target.value };
      }
    });
    setCargos(novoCargos);
  };

  return (
    <div className="mb-4" key={index}>
      <Row className="mb-2 justify-content-center d-flex" lg>
        <Col className="fw-bold mb-3 flex-grow-1" sm={4}>
          <Row className="px-2">Nome do cargo</Row>
          <Row className="px-2">
            <Form.Group className="p-0" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                name="nome"
                onChange={handleOnChange}
                value={item.nome}
                key={index}
                required
              />
            </Form.Group>
          </Row>
        </Col>

        <Col className="fw-bold mb-3" lg>
          <Row className="px-2">Simbologia</Row>
          <Row className="px-2">
            <Form.Group className="p-0" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                name="simbolo"
                onChange={handleOnChange}
                value={item.simbolo}
                key={index}
                required
              />
            </Form.Group>
          </Row>
        </Col>
        <Col className="fw-bold mb-3" lg>
          <Row className="px-2">Escolaridade</Row>
          <Row className="px-2">
            <Form.Select
              aria-label="Default select example"
              name="escolaridade"
              onChange={handleOnChange}
              value={item.escolaridade || "disabled"}
              key={index}
              required
            >
              <option disabled value={"disabled"}></option>
              <option value="nenhum">Nenhum</option>
              <option value="Ensino Fundamental">Ensino Fundamental</option>
              <option value="Ensino Fundamental Incompleto">
                Ensino Fundamental Incompleto
              </option>
              <option value="Ensino Médio">Ensino Médio</option>
              <option value="Ensino Médio Técnico">Ensino Médio Técnico</option>
              <option value="Ensino Superior">Ensino Superior</option>
              <option value="Magistério">Magistério</option>
            </Form.Select>
          </Row>
        </Col>

        <Col className="fw-bold mb-3" lg>
          <Row className="px-2">Vencimento</Row>
          <Row className="px-2">
            <Form.Group className="p-0" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="number"
                name="vencimento"
                onChange={handleOnChange}
                value={item.vencimento}
                key={index}
                required
              />
            </Form.Group>
          </Row>
        </Col>

        <Col className="fw-bold mb-3" lg>
          <Row className="px-2">Valor</Row>
          <Row className="px-2">
            <Form.Group className="p-0" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="number"
                name="valor"
                onChange={handleOnChange}
                value={item.valor}
                key={index}
                required
              />
            </Form.Group>
          </Row>
        </Col>
        <Col className="d-flex align-items-center justify-content-center flex-grow-0">
          {cargos.length > 0 && (
            <Button
              variant="danger"
              className="fw-bold me-1"
              onClick={() => hundleMenosCampo(index)}
              style={{ height: "36px", marginTop: "5px" }}
            >
              Remover
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GrupoCargos;
