import { Col, Form, Row } from "react-bootstrap";

const Municipios = ({label,estados,objeto,change}) => {

  const hundleChange = (e) => {
    change({...objeto, [e.target.name]: e.target.value})
  }

  return (
    <Form.Group
      as={Row}
      controlId="municipio"
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={4}>
        <Form.Control
          required
          type="text"
          placeholder=""
          value={objeto['municipio']}
          name="municipio"
          onChange={hundleChange}
        />
      </Col>

      <Form.Label column sm={1} className="fw-bold text-sm-center">
        UF
      </Form.Label>

      <Col sm={3}>
        <Form.Select aria-label="Default select example" defaultValue={objeto['uf']||'disabled'} name="uf" onChange={hundleChange}>
          <option value={'disabled'} disabled></option>
          {
            estados.map(estado => {return(
              <option key={estado} value={estado}>{estado}</option>
            )})
          }
        </Form.Select>
      </Col>
    </Form.Group>
  );
};

export default Municipios;
