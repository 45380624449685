import { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import "./styles.css";

export default function Dropdown({
  selected,
  setSelected,
  options,
  index,
}) {
  const [isActive, setIsActive] = useState(false);

  function handleClick(option) {
    setSelected(option, index);
    setIsActive(false);
  }

  const filter = options.filter((opt) => {
    if (selected !== opt) return opt;
  });

  return (
    <div className="dp">
      <div className="dp-btn" onClick={() => setIsActive(!isActive)}>
        {selected}
        {!isActive ? <ChevronDown /> : <ChevronUp />}
      </div>
      {isActive && (
        <div className="dp-content">
          {filter?.length > 0 &&
            filter.map((opt) => (
              <div
                className="dp-item"
                onClick={() => handleClick(opt)}
                key={opt}
              >
                {opt}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
