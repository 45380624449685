import { Download } from "lucide-react";
import { Button } from "react-bootstrap";

export default function ExcelButton({
  id,
  cargo,
  status,
  pagamento,
  deleted_at,
  isencao,
}) {
  const openLinkInNewTab = () => {
    const fullPath =
      process.env.REACT_APP_BACK_ADMIN +
      `/listar-inscritos-excel/${id}?page=1&qtd=25&status=${
        status || ""
      }&cargo=${cargo || ""}&pagamento=${pagamento || ""}&deleted_at=${
        deleted_at || ""
      }&isencao=${isencao || ""}`;

    window.open(fullPath, "_blank");
  };

  return (
    <Button>
      <Download onClick={openLinkInNewTab} />
    </Button>
  );
}
