import { useState } from "react";
import { Card, Container, Form, Button, Alert } from "react-bootstrap";
import Auth from "../../helpers/Auth";
import Loading from "../../components/Loading";
import CespecSVG from "../../assets/Cespec";

const Login = () => {
  const { http, setToken } = Auth();
  const [loading, setLoading] = useState(false);
  const [incorreto, setIncorreto] = useState(null);
  const [login, setLogin] = useState({
    nome: "",
    senha: "",
  });

  const hundleLogin = (e) => {
    setLogin({ ...login, nome: e.target.value });
  };

  const hundleSenha = (e) => {
    setLogin({ ...login, senha: e.target.value });
  };

  const hundleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    http
      .post("/login", login)
      .then((res) => {
        setToken(res.data.user, res.data.authorisation.token);
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Usuário não existe ou senha incorreta."
        )
          setIncorreto("Login inválido");
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Container className="position-relative">
        {loading && (
          <div
            className="bg-white bg-opacity-75 position-absolute top-50 start-50 translate-middle w-100"
            style={{ zIndex: "1000" }}
          >
            <Loading />
          </div>
        )}

        <div
          className="d-flex justify-content-center align-items-center z-0"
          style={{ height: "100vh" }}
        >
          <Card className="shadow rounded bg-body-tertiary border-0 py-4">
            <Form
              className="d-flex flex-column justify-content-around h-100"
              onSubmit={hundleSubmit}
            >
              {/* <img src="cespec.png" className="my-3 mx-5" alt="logo" /> */}

              <CespecSVG
                className="mx-5 mb-3"
                width={300}
                height={(300 / 353) * 145}
                viewBox="0 0 300 80"
              />
              
              {incorreto && (
                <div className="text-center mb-2 mx-5">
                  <Alert key={"danger"} variant={"danger "}>
                    <span className="text-danger">{incorreto}</span>
                  </Alert>
                </div>
              )}

              <div>
                <Form.Group className="mb-3 mx-5" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Login"
                    onChange={hundleLogin}
                    className="rounded-pill"
                  />
                </Form.Group>

                <Form.Group className="mb-3 mx-5" controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    onChange={hundleSenha}
                    className="rounded-pill"
                  />
                </Form.Group>
                <div className="d-grid gap-2 mx-5 mb-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill"
                  >
                    Entrar
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Login;
