import GrupoCargos from "../../../components/Input/Grupo/GrupoCargos";
import { useContext } from "react";
import { ConcursoContext } from "../../../context/ConcursoContext";
import { Button } from "react-bootstrap";

const Cargos = () => {
  const { cargos, hundleMaisCampo } = useContext(ConcursoContext);

  return (
    <>
      <div className="d-flex justify-content-end my-4">
        <Button
          variant="primary"
          className="fw-bold"
          onClick={hundleMaisCampo}
          style={{ height: "36px" }}
        >
          Adicionar
        </Button>
      </div>

      {cargos.map((item, i) => {
        return <GrupoCargos item={item} key={i} index={i} />;
      })}
    </>
  );
};

export default Cargos;
