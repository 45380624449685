import { Col, Form, Row } from "react-bootstrap";

const InputTexto = ({ label, change, objeto, tipo, required }) => {
  const hundleChange = (e) => {
    change({ ...objeto, [tipo]: e.target.value });
  };

  return (
    <Form.Group
      as={Row}
      controlId={label}
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={8}>
        <Form.Control
          required={required}
          type="text"
          placeholder=""
          value={objeto[tipo]}
          onChange={hundleChange}
          onPaste={(e) => e.preventDefault()}
        />
      </Col>
    </Form.Group>
  );
};

export default InputTexto;
