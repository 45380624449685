import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Titulo from "../../../components/Titulo";
import Auth from "../../../helpers/Auth";
import { useState } from "react";
// import Loading from "../../../components/Loading";
import Swal from "sweetalert2";

const Retorno = () => {
  const { http } = Auth();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  const handleAlerta = () => {
    Swal.fire({
      icon: "success",
      text: `Arquivo RET enviado com sucesso`,
      confirmButtonText: "Ok",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setWarning(null);
  
    const extensao = file.name.split(".");
    if (extensao[extensao.length - 1] === "RET") {
      const formData = new FormData();
      formData.append("file", file);
      http
        .post("/pagamento-ret", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          handleAlerta();
          setLoading(false); // Movido para cá
        })
        .catch((error) => {
          console.log(error);
          setLoading(false); // Movido para cá em caso de erro
        });
    } else {
      setWarning("Importe um arquivo RET");
      setLoading(false); // Movido para cá em caso de extensão inválida
    }
  };
  
  // if (loading) return <Loading />;
  // else
    return (
      <>
        <Titulo
          texto={"Importar"}
          subtexto={"Importação de Arquivo de Retorno"}
        />

        {warning && (
          <div className="text-center mx-5">
            <Alert key={"danger"} variant={"danger "}>
              <span className="text-danger">{warning}</span>
            </Alert>
          </div>
        )}

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Control
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  accept=".RET"
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Button style={{width:'90px'}} type="submit">
                {loading ? <Spinner size="sm" /> : "Importar"}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
};

export default Retorno;
