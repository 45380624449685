import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Auth from "./helpers/Auth";
import AppRoutes from "./routes";
// import VLibras from "@djpfs/react-vlibras";

function App() {
  const { getToken } = Auth();

  if (!getToken()) {
    return (
      <>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to={"/login"} />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
