import { Col, Form, Row } from "react-bootstrap";

const Selecao = ({ label, items, change, objeto, tipo, lista, number }) => {

  const hundleSelected = (e) => {
    if (number) {
      change({ ...objeto, [tipo]: parseInt(e.target.value) });
    } else {
      change({ ...objeto, [tipo]: e.target.value });
    }
  };

  if (items) {
    if (lista) {
      return (
        <Form.Group
          as={Row}
          controlId={tipo}
          className="mb-4 row align-items-center justify-content-center"
          key={label}
        >
          <Form.Label column sm={2} className="fw-bold text-sm-end">
            {label}
          </Form.Label>

          <Col sm={8}>
            <Form.Select
              required
              aria-label="Default select example"
              defaultValue={objeto[tipo]||"disabled"}
              onChange={hundleSelected}
            >
              <option key={"disabled"} value={"disabled"} disabled></option>
              {items.map((obj) => {
                return <option key={obj} value={obj} label={obj} />;
              })}
            </Form.Select>
          </Col>
        </Form.Group>
      );
    } else {
      return (
        <Form.Group
          as={Row}
          controlId="validationCustom01"
          className="mb-4 row align-items-center justify-content-center"
          key={label}
        >
          <Form.Label column sm={2} className="fw-bold text-sm-end">
            {label}
          </Form.Label>

          <Col sm={8}>
            <Form.Select
              required
              aria-label="Default select example"
              defaultValue={objeto[tipo]||"disabled"}
              onChange={hundleSelected}
            >
              <option key={"disabled"} value={"disabled"} disabled></option>
              {items.map((obj) => {
                return <option key={obj.id} value={obj.id} label={obj.nome} />;
              })}
            </Form.Select>
          </Col>
        </Form.Group>
      );
    }
  }
};

export default Selecao;
