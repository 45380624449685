import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

const BotaoRemove = ({ id, change, items, remove }) => {

  const handleAlerta = () => {
    Swal.fire({
      icon: "warning",
      text: `Deseja remover?`,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Remover",
      cancelButtonText: "Cancelar",
      iconColor: "#dc3545",
    }).then((resp) => {
      if (resp.isConfirmed) hundleRemove()
    })
  }

  const hundleRemove = () => {
    const removed = items.filter((item) => item.id !== id);
    change(removed)
    remove(id)
  };

  return (
    <Button variant="danger" className="fw-bold" onClick={handleAlerta}>
      Remover
    </Button>
  )
}

export default BotaoRemove;
