import { Col, Form, Row } from "react-bootstrap";

const TextAreas = ({ label, change, objeto, tipo }) => {
  
  const hundleChange = (e) => {
    change({ ...objeto, [tipo]: e.target.value });
  };

  return (
    <Row className="mb-4 row align-items-center justify-content-center">
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={8}>
        <Form.Control as="textarea" rows={3} onChange={hundleChange} value={objeto[tipo]}/>
      </Col>
    </Row>
  );
};

export default TextAreas;
