import { Form } from "react-bootstrap";

export default function Select({
  defaultValue,
  label = null,
  data,
  id,
  nome,
  ...rest
}) {
  return (
    <Form.Group className="mb-3 fw-bold" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Select defaultValue={defaultValue} {...rest}>
        <option value="default" disabled></option>
        {data.map((item) => (
          <option key={item[id]} value={item[id]}>
            {item[nome]}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
