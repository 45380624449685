import { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import Auth from "../helpers/Auth";

export const ConcursoContext = createContext();

export const ConcursoProvider = ({ children }) => {

  const [id,setId] = useState()
  const { http } = Auth();
  
  const cargo = {
    nome: "",
    simbolo: "",
    escolaridade: "",
    valor: "",
    vencimento:""
  }

  const cargoID = {
    nome: "",
    simbolo: "",
    escolaridade: "",
    valor: "",
    vencimento:"",
    concurso_id:id
  }

  const isencao = {
    nome: "",
    descricao:"",
  }

  const isencaoID = {
    nome: "",
    descricao:"",
    concurso_id:id,
  }

  const [cargos, setCargos] = useState([])

  const [isencoes, setIsencoes] = useState([])

  const hundleMaisCampo = () => {
    if (id) {
      setCargos([...cargos, cargoID]);
    } else {
      setCargos([...cargos, cargo]);
    }
  }

  const hundleMenosCampo = (index) => {
    const lista = [...cargos];
    Swal.fire({
      icon: "warning",
      text: `Deseja remover?`,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Remover",
      cancelButtonText: "Cancelar",
      iconColor: "#dc3545",
    }).then((resp) => {
      if (resp.isConfirmed) {
        if (!id || !cargos[index].id) {
          lista.splice(index, 1)
          setCargos(lista)
        } else {
          http.post('deletar-cargo', {
            concurso_id: id,
            cargo_id: cargos[index].id
          })
          lista.splice(index, 1)
          setCargos(lista)
        }
      }
    })
  }

  const hundleMaisCampoIsencao = () => {
    if (id) {
      setIsencoes([...isencoes, isencaoID]);
    } else {
      setIsencoes([...isencoes, isencao]);
    }
  }

  const hundleMenosCampoIsencao = (index) => {
    const lista = [...isencoes]
    Swal.fire({
      icon: "warning",
      text: `Deseja remover?`,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Remover",
      cancelButtonText: "Cancelar",
      iconColor: "#dc3545",
    }).then((resp) => {
      if (resp.isConfirmed) {
        if (!id || !isencoes[index].id) {
          lista.splice(index, 1)
          setIsencoes(lista)
        } else {
          http.post('deletar-isencao', {
            concurso_id: id,
            isencao_id: isencoes[index].id
          })
          lista.splice(index, 1)
          setIsencoes(lista)
        }
      }
    })
  }

  const [pessoal, setPessoal] = useState({
    cliente_id: "",
    nome: "",
    modalidade:"",
    txt_cartao: "",
    status: "",
    descricao: "",
    municipio: "",
    uf: "",
    inscricao_encerramento: "",
    boleto_vencimento: "",
    oculto: 0,
    hora_prova: "",
    data_prova: "",
    hora: "00",
    minuto:"00",
    inicio_boleto_indeferido: "",
    fim_boleto_indeferido: "",
    vencimento_boleto_indeferido: ""
  })

  const [concurso, setConcurso] = useState({})

  useEffect(() => {
    setConcurso({pessoal, cargos, isencoes})
  }, [pessoal, cargos, isencoes])

  const valor = {
    setId,
    pessoal,
    setPessoal,
    cargos,
    setCargos,
    cargo,
    cargoID,
    concurso,
    setConcurso,
    hundleMaisCampo,
    hundleMenosCampo,
    hundleMaisCampoIsencao,
    hundleMenosCampoIsencao,
    isencao,
    isencoes,
    setIsencoes,
    id
  };

  return (
    <ConcursoContext.Provider value={valor}>
      {children}
    </ConcursoContext.Provider>
  );
};
