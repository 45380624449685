import { Pagination } from "react-bootstrap";

const Pagina = ({total, current, onChangePage}) => {

    const items = []

    if (total <= 7) {

        if (current>1) items.push(<Pagination.Prev key={'prev'} onClick={()=>onChangePage(current-1)}/>)

        for (let page = 1; page <= total ; page++) {
            items.push(
                <Pagination.Item key={page} data-page={page} active={page === current} onClick={()=>onChangePage(page)}>
                    {page}
                </Pagination.Item>
            )
        }
    
        if (current<total) items.push(<Pagination.Next key={'next'} onClick={()=>onChangePage(current+1)}/>)

    } else {

        if (current>=5) items.push(<Pagination.First key={'first'} onClick={()=>onChangePage(1)}/>)
        if (current>1) items.push(<Pagination.Prev key={'prev'} onClick={()=>onChangePage(current-1)}/>)

        if (current >= 5) {
            for (let page = current-3; page <= current+3 ; page++) {
                if (page <= total) {
                    items.push(
                        <Pagination.Item key={page} data-page={page} active={page === current} onClick={()=>onChangePage(page)}>
                            {page}
                        </Pagination.Item>
                    )
                }
            }
        } else {
            for (let page = 1; page <= 7 ; page++) {
                items.push(
                    <Pagination.Item key={page} data-page={page} active={page === current} onClick={()=>onChangePage(page)}>
                        {page}
                    </Pagination.Item>
                )
            }
        }
    
        if (current<total) items.push(<Pagination.Next key={'next'} onClick={()=>onChangePage(current+1)}/>)
        if (current<total-3) items.push(<Pagination.Last key={'next'} onClick={()=>onChangePage(total)}/>)

    }


    return (
        <div className="d-flex justify-content-center">
            <Pagination>
                {items}
            </Pagination>
        </div>
     );
}
 
export default Pagina