import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../../helpers/Auth";
import CespecSVG from "../../assets/Cespec";

const Menu = () => {
  const { token, logout } = Auth();

  const hundleLogout = () => {
    if (token !== undefined) logout();
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to={"/inicio"}>
          <CespecSVG
            width={140}
            height={(140 / 353) * 145}
            viewBox="0 0 300 80"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end fs-6 w-100 gap-lg-4">
            <Nav.Link as={Link} to={"/inicio"}>
              <span>Início</span>
            </Nav.Link>
            <Nav.Link as={Link} to={"/clientes"}>
              <span>Clientes</span>
            </Nav.Link>
            <Nav.Link as={Link} to={"/concursos"}>
              <span>Concursos</span>
            </Nav.Link>
            <Nav.Link as={Link} to={"/usuarios"}>
              <span>Candidatos</span>
            </Nav.Link>
            <Nav.Link as={Link} to={"/admistradores"}>
              <span>Administradores</span>
            </Nav.Link>
            <Nav.Link onClick={hundleLogout}>
              <span>Sair</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
