import Selecao from "../../../components/Input/Selecao";
import InputTexto from "../../../components/Input/Texto";
import TextAreas from "../../../components/Input/Areas";
import Date from "../../../components/Input/Date";
import Municipios from "../../../components/Input/Selecao/Municipios";
import { useContext, useEffect, useState } from "react";
import Auth from "../../../helpers/Auth";
import { ConcursoContext } from "../../../context/ConcursoContext";
import Estados from "../../../database/Estados";
import Checkbox from "../../../components/Input/Checkbox";
import Loading from "../../../components/Loading";
import Swal from "sweetalert2";
import Hora from "../../../components/Hora";
import DoubleDate from "../../../components/Input/Date/Double";

const Pessoal = () => {
  const [clientes, setClientes] = useState();
  const [modalidades, setModalidades] = useState();
  const [loading, setLoading] = useState(true);
  const { http, logout } = Auth();

  const status = ["Aberto", "Em Andamento", "Encerrado", "Suspenso"];

  const { pessoal, setPessoal } = useContext(ConcursoContext);

  useEffect(() => {
    (async () => {
      try {
        const res1 = await http.get("pegar-cliente");
        setClientes(res1.data);
        const res2 = await http.get("modalidade");
        setModalidades(res2.data);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (clientes) {
    return (
      <>
        <Selecao
          label={"Cliente"}
          items={clientes}
          change={setPessoal}
          objeto={pessoal}
          tipo={"cliente_id"}
          number
        />
        <InputTexto
          label={"Nome"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"nome"}
          required
        />
        <Selecao
          label={"Modalidade"}
          items={modalidades}
          change={setPessoal}
          objeto={pessoal}
          tipo={"modalidade"}
          number
        />
        <InputTexto
          label={"Título Cartão"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"txt_cartao"}
        />
        <Selecao
          label={"Status"}
          items={status}
          change={setPessoal}
          objeto={pessoal}
          tipo={"status"}
          lista
        />
        <TextAreas
          label={"Descrição"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"descricao"}
        />
        <Municipios
          label={"Município"}
          estados={Estados}
          objeto={pessoal}
          change={setPessoal}
        />
        <Date
          label={"Data da Prova"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"data_prova"}
        />
        <Hora
          label={"Horário da Prova"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"data_prova"}
        />
        <Date
          label={"Encerramento da Inscrição"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"inscricao_encerramento"}
        />
        <Date
          label={"Vencimento do Boleto"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"boleto_vencimento"}
        />

        <DoubleDate
          label={"Periodo dos Boleto das Isenções Indeferidas"}
          change={setPessoal}
          objeto={pessoal}
          tipo1="inicio_boleto_indeferido"
          tipo2="fim_boleto_indeferido"
        />

        <Date
          label={"Vencimento do Boleto das Isenções Indeferidas"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"vencimento_boleto_indeferido"}
        />

        <Checkbox
          label={"Oculto"}
          change={setPessoal}
          objeto={pessoal}
          tipo={"oculto"}
        />
      </>
    );
  }
};

export default Pessoal;
