import { Col, Form, Row } from "react-bootstrap";

const DoubleDate = ({ label, change, objeto, tipo1, tipo2 }) => {
  const hundleChange = (e) => {
    const { name, value } = e.target;
    change({ ...objeto, [name]: value });
  };

  return (
    <Form.Group
      as={Row}
      controlId="doubledate"
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={3}>
        <Form.Control
          type="date"
          name={tipo1}
          onChange={hundleChange}
          value={objeto[tipo1]}
          required
        />
      </Col>

      <Form.Label column sm={1} className="fw-bold text-sm-center">
        até
      </Form.Label>

      <Col sm={4}>
        <Form.Control
          type="date"
          name={tipo2}
          onChange={hundleChange}
          value={objeto[tipo2]}
          required
        />
      </Col>
    </Form.Group>
  );
};

export default DoubleDate;
