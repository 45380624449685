import { Container, Form } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import InputTexto from "../../components/Input/Texto";
import { useEffect, useState } from "react";
import Botoes from "./components/Botoes";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Swal from "sweetalert2";
import InputTextoMask from "../../components/Input/Mask";

const EditarCliente = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { http, logout } = Auth();
  const [loading, setLoading] = useState(true);
  const [cliente, setCliente] = useState({});

  const hundleSubmit = (e) => {
    e.preventDefault();
    const submit = { ...cliente };
    editarCliente(submit);
  };

  const editarCliente = async (request) => {
    try {
      await http.put(`editar-cliente/${params.id}`, request);
      navigate("/clientes");
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await http.get(`cliente/${params.id}`);
        setCliente(res.data.cliente);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
          navigate("/clientes");
        }
      }
    })();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <Titulo texto={"Editar Cliente"} />

      <Form onSubmit={hundleSubmit}>
        <InputTexto
          label="Fantasia"
          change={setCliente}
          tipo={"nome"}
          objeto={cliente}
          required
        />
        <InputTexto
          label="R. Social"
          change={setCliente}
          tipo={"pj_razao"}
          objeto={cliente}
          required
        />
        <InputTextoMask
          label="CNPJ"
          change={setCliente}
          tipo={"pj_cnpj"}
          objeto={cliente}
          mask={'00.000.000/0000-00'}
          required
        />
        <InputTexto
          label="I.M."
          change={setCliente}
          tipo={"pj_im"}
          objeto={cliente}
        />
        <InputTexto
          label="I.E."
          change={setCliente}
          tipo={"pj_ie"}
          objeto={cliente}
        />
        <InputTexto
          label="Suframa"
          change={setCliente}
          tipo={"pj_suframa"}
          objeto={cliente}
        />
        <Botoes />
      </Form>
    </Container>
  );
};

export default EditarCliente;
