import {
  Button,
  Container,
  Form,
  Tab,
  Tabs,
  Col,
  Stack,
  Row,
} from "react-bootstrap";
import Inscritos from "./components/Inscritos";
import Etapas from "./components/Etapas";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Auth from "../../helpers/Auth";
import Loading from "../../components/Loading";
import Titulo from "../../components/Titulo/";
import Pagina from "../../components/Pagina";
import Swal from "sweetalert2";
import ExcelButton from "../../components/Botao/Excel";
// import Pontuacao from "./components/Pontuacao";

const Inscricao = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { logout, http } = Auth();
  const [loading, setLoading] = useState(true);
  const [inscritos, setInscritos] = useState();
  const [cargos, setCargos] = useState([]);
  const [tipoIsencao, setTipoIsencao] = useState([]);
  const [isIsencao, setIsIsencao] = useState(false);
  const [filtro, setFiltro] = useState({
    status: "",
    cargo: "",
    pagamento: "",
    deleted_at: true,
    isencao: "",
  });
  const [page, setPage] = useState(1);
  const [adicionar, setAdicionar] = useState(false);
  const [infos, setInfos] = useState({ pages: 1, total: 0 });
  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);

  console.log("É isencao? ", isIsencao);

  const width = window.innerWidth;

  function handleChangeFilter(e) {
    const { name, value } = e.target;
    const filter = { ...filtro, [name]: value };
    setFiltro(filter);
  }

  async function getInscricoes(e = null, type = null) {
    if (e) e.preventDefault();
    setLoading(true);
    setIsIsencao(
      type === "reset"
        ? false
        : filtro.deleted_at === true &&
            filtro.pagamento === "" &&
            (filtro.isencao !== "" || filtro.status !== "")
    )
    try {
      await http
        .get(`listar-inscritos/${id}`, {
          params: {
            page,
            qtd: 25,
            status: type === "reset" ? "" : filtro.status,
            cargo: type === "reset" ? "" : filtro.cargo,
            pagamento: type === "reset" ? "" : filtro.pagamento,
            deleted_at: type === "reset" ? true : filtro.deleted_at,
            isencao: type === "reset" ? "" : filtro.isencao,
          },
        })
        .then((resp) => {
          setInscritos(resp.data.data);
          setInfos({
            pages: resp.data.totalPaginas,
            total: resp.data.totalItens,
          });
        });
      await http
        .get(`concurso/admin/${id}`)
        .then((res) => setCargos(res.data.cargos));
      await http
        .get(`list/isencao/${id}`)
        .then((res) => setTipoIsencao(res.data));
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
        navigate("/concursos");
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInscricoes();
  }, [page]);

  console.log(cargos)

  if (loading) return <Loading />;
  else
    return (
      <Container>
        {inscritos?.[0]?.concursoNome && (
          <Titulo texto={inscritos[0]?.concursoNome} />
        )}

        <Tabs
          defaultActiveKey="Pessoal"
          id="justify-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Pessoal" title="Inscrições">
            {cargos.length > 0 && (
              <Form className="my-5" onSubmit={(e) => getInscricoes(e)}>
                <Stack
                  direction={width <= 760 ? "vertical" : "horizontal"}
                  gap={3}
                  className="mb-3"
                >
                  <p className="fs-3 m-0">Filtros</p>
                  <Stack direction="horizontal" className={"ms-auto"}>
                    <Button className="me-3" type="submit">
                      Filtrar
                    </Button>
                    <Button
                      type="submit"
                      variant="danger"
                      onClick={(e) => {
                        setFiltro({
                          concurso: "",
                          status: "",
                          pagamento: "",
                          deleted_at: true,
                          isencao: "",
                        });
                        getInscricoes(e, "reset");
                      }}
                    >
                      Limpar
                    </Button>
                  </Stack>
                </Stack>
                <Row lg>
                  <Col lg className="mb-2">
                    <Form.Select
                      onChange={handleChangeFilter}
                      name="deleted_at"
                      defaultValue={filtro.deleted_at || true}
                    >
                      <option value={true}>Todas Inscrições</option>
                      <option value={false}>Apenas Inscrições Válidas</option>
                      <option value={"cargos-inscritos"}>
                        Cargos Inscritos
                      </option>
                      <option value={"recurso"}>
                        Apenas Inscrições com Recurso
                      </option>
                      <option value={"classificado"}>
                        Apenas Inscrições Classificadas Válidas
                      </option>
                      <option value={"todos-classificado"}>
                        Total Inscrições Pagas/Isentas
                      </option>
                      <option value={"deferido-pago"}>
                        Inscrições Pagas e Deferidas pela mesma pessoa
                      </option>
                    </Form.Select>
                  </Col>
                  <Col lg className="mb-2">
                    <Form.Select
                      onChange={handleChangeFilter}
                      name="pagamento"
                      defaultValue={filtro.pagamento || "default"}
                    >
                      <option value={"default"} disabled>
                        Status do Pagamento
                      </option>
                      <option value={1}>Pago</option>
                      <option value={0}>Pendente</option>
                    </Form.Select>
                  </Col>

                  <Col lg className="mb-2">
                    <Form.Select
                      onChange={handleChangeFilter}
                      name="cargo"
                      defaultValue={filtro.cargo || "default"}
                    >
                      <option disabled value={"default"}>
                        Selecionar um cargo
                      </option>
                      {cargos?.length > 0 &&
                        cargos.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nome}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col lg className="mb-2">
                    <Form.Select
                      onChange={handleChangeFilter}
                      name="status"
                      defaultValue={filtro.status || "default"}
                    >
                      <option value={"default"} disabled>
                        Status da Isenção
                      </option>
                      <option value="Em análise da comissão">
                        Em análise da comissão
                      </option>
                      <option value="Deferido">Deferido</option>
                      <option value="Indeferido">Indeferido</option>
                      <option value="Indeferido com recurso">
                        Indeferido com recurso
                      </option>
                    </Form.Select>
                  </Col>
                  <Col lg className="mb-2">
                    <Form.Select
                      onChange={handleChangeFilter}
                      name="isencao"
                      defaultValue={filtro.isencao || "default"}
                    >
                      <option value={"default"} disabled>
                        Tipo de Isenção
                      </option>
                      {tipoIsencao?.map((item) => (
                        <option key={item.id} value={item.nome}>
                          {item.nome}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form>
            )}

            <div className="d-flex justify-content-between lign-items-center mb-3">
              <span className="d-flex align-items-center gap-1">
                <strong>Total: </strong>
                {infos.total}
              </span>
              <ExcelButton id={id} {...filtro} />
            </div>

            {/* <Salas id={id}/> */}

            <Inscritos
              inscritos={inscritos}
              setInscritos={setInscritos}
              isIsencao={isIsencao}
            />
            {infos.pages > 1 && (
              <Pagina
                total={infos.pages}
                current={page}
                onChangePage={handleChangePage}
              />
            )}
          </Tab>
          <Tab eventKey="Cargos" title="Etapas">
            <Etapas adicionar={adicionar} setAdicionar={setAdicionar} />
          </Tab>
        </Tabs>
        {!adicionar && (
          <Button variant="secondary" onClick={() => navigate("/concursos")}>
            Voltar
          </Button>
        )}
      </Container>
    );
};

export default Inscricao;
