import { Button } from "react-bootstrap";
import AddEtapa from "./AddEtapa";
import TabelaEtapa from "./TabelaEtapa";

const Etapas = ({adicionar, setAdicionar}) => {
  
  const handleShow = () => {
    setAdicionar(!adicionar)
  }

  if (adicionar) return <AddEtapa handleShow={handleShow}/>

  else return (
    <>
      <div className="my-3" >
        <Button onClick={handleShow}>Adicionar</Button>
      </div>
      
      <TabelaEtapa/>
    </>
  );
};

export default Etapas;
