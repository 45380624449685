import { useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ConcursoContext } from "../../../context/ConcursoContext";

const AddIsencoes = ({ item, index }) => {
  const { isencoes, setIsencoes, hundleMenosCampoIsencao } =
  useContext(ConcursoContext);

  const handleOnChange = (e) => {
    const novaIsencoes = isencoes.map((cargo, i) => {
      if (i !== index) {
        return cargo;
      } else {
        return { ...cargo, [e.target.name]: e.target.value };
      }
    });
    setIsencoes(novaIsencoes);
  };

  return (
    <div className="mb-4" key={index}>
      <Row className="mb-2 justify-content-center d-flex" lg>
        
        <Col className="fw-bold mb-3 flex-grow-2" lg>
          <Row className="px-2">Nome</Row>
          <Row className="px-2">
            <Form.Group className="p-0" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                name="nome"
                onChange={handleOnChange}
                value={item.nome||''}
                key={index}
                required
              />
            </Form.Group>
          </Row>
        </Col>

        <Col className="fw-bold mb-3 flex-grow-2" lg>
          <Row className="px-2">Descrição</Row>
          <Row className="px-2">
            <Form.Group className="p-0" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="textarea" 
                rows={1}
                
                name="descricao"
                onChange={handleOnChange}
                value={item.descricao||''}
                key={index}
                required
              />
            </Form.Group>
          </Row>
        </Col>

        <Col className="d-flex align-items-center justify-content-end flex-grow-0" sm={2}>
          {isencoes.length > 0 && (
            <Button
              variant="danger"
              className="fw-bold me-1"
              onClick={() => hundleMenosCampoIsencao(index)}
              style={{height:'36px', marginTop:'5px'}}
            >
              Remover
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );

}
 
export default AddIsencoes;