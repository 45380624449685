import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import Swal from "sweetalert2";

const AddEtapa = ({handleShow}) => {
    const { id } = useParams()
    const {http, logout} = Auth()
    const [etapa, setEtapa] = useState({
      concurso_id:id,
      nome: "",
      realizacao: "",
      correcao: "",
      total_questoes: "",
      corretas: "",
      eliminatoria: 0,
      objetivas: 0,
    })
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setEtapa({ ...etapa, [name]: value });
    };
  
    const handleCheck = (e) => {
      const { name, value } = e.target;
      if (parseInt(value) === 0) setEtapa({ ...etapa, [name]: 1 });
      else setEtapa({ ...etapa, [name]: 0 });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault()
      http.post('etapas',etapa)
          .then(handleShow())
          .catch(error =>{
            if (error.response.status === 401) logout();
            else {
              Swal.fire({
                icon: "error",
                text: 'Algum erro com o servidor aconteceu. Tente novamente!',
                confirmButtonColor: "#dc3545",
              });
            }
          })
      
    };
  
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="nome">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            value={etapa.nome}
            onChange={handleChange}
            name="nome"
            type="text"
          />
        </Form.Group>
  
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="realizacao">
              <Form.Label>Realização</Form.Label>
              <Form.Control
                required
                value={etapa.realizacao}
                onChange={handleChange}
                name="realizacao"
                type="date"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="correcao">
              <Form.Label>Correção</Form.Label>
              <Form.Control
                required
                value={etapa.correcao}
                onChange={handleChange}
                name="correcao"
                type="date"
              />
            </Form.Group>
          </Col>
        </Row>
  
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="total">
              <Form.Label>Total de Questões</Form.Label>
              <Form.Control
                required
                value={etapa.total_questoes}
                onChange={handleChange}
                name="total_questoes"
                type="number"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="maximo">
              <Form.Label>Max. Corretas / Questão</Form.Label>
              <Form.Control
                required
                value={etapa.corretas}
                onChange={handleChange}
                name="corretas"
                type="number"
              />
            </Form.Group>
          </Col>
        </Row>
  
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="eliminatoria">
              <Form.Check
                checked={etapa.eliminatoria === 1 ? true : false}
                value={etapa.eliminatoria}
                onChange={handleCheck}
                label="Eliminatória"
                name="eliminatoria"
                type="checkbox"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="objetiva">
              <Form.Check
                checked={etapa.objetivas === 1 ? true : false}
                value={etapa.objetivas}
                onChange={handleCheck}
                label="Questão Objetiva"
                name="objetivas"
                type="checkbox"
              />
            </Form.Group>
          </Col>
        </Row>
  
        <Button onClick={handleShow} variant="secondary" type="submit" style={{width:'100px'}} className="me-2">
          Cancelar
        </Button>
        <Button variant="primary" type="submit" style={{width:'100px'}}>
          Finalizar
        </Button>
      </Form>
    )
};

export default AddEtapa;
