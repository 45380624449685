import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const Checkbox = ({ label, change, objeto, tipo }) => {
  const [checkbox, setCheckbox] = useState(objeto[tipo] === 1 ? true : false);

  const hundleChange = (e) => {
    change({ ...objeto, [tipo]: parseInt(e.target.value) })
    setCheckbox((!checkbox === true ? 1 : 0 ))
  };


  return (
    <Form.Group
      as={Row}
      controlId="validationCustom01"
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={8}>
        <Form.Check type="checkbox" value={!checkbox === true ? 1 : 0} onChange={hundleChange} checked={checkbox}/>
      </Col>
    </Form.Group>
  );
};

export default Checkbox;
