import * as React from "react";

const CespecSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <defs>
      <linearGradient
        id="id2"
        x1={0}
        x2={72.78}
        y1={59.96}
        y2={59.96}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#fe8806",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#eeb41c",
          }}
        />
      </linearGradient>
      <linearGradient
        id="id3"
        x1={2.21}
        x2={75.59}
        y1={54.63}
        y2={54.63}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#eeb41c",
          }}
        />
        <stop
          offset={0.729}
          style={{
            stopOpacity: 1,
            stopColor: "#f69e11",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#fe8806",
          }}
        />
      </linearGradient>
      <linearGradient
        id="id4"
        x1={9.4}
        x2={91.44}
        y1={46.97}
        y2={46.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#07357a",
          }}
        />
        <stop
          offset={0.51}
          style={{
            stopOpacity: 1,
            stopColor: "#0334c1",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#2583ff",
          }}
        />
      </linearGradient>
      <linearGradient
        id="id5"
        x1={12.84}
        x2={56.9}
        y1={16.62}
        y2={16.62}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#0334c1",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#07357a",
          }}
        />
      </linearGradient>
      <linearGradient
        id="id6"
        x1={8.83}
        x2={79.02}
        y1={22.31}
        y2={22.31}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#0630b7",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#2583ff",
          }}
        />
      </linearGradient>
      <clipPath id="id0">
        <path d="m75.97 32.29 15.47-16.56C82.84 6.08 70.32 0 56.37 0 30.43 0 9.4 21.03 9.4 46.97c0 .67.01 1.33.04 1.99l17.83 11.63 6.35-4.55c-1.12-2.8-1.74-5.86-1.74-9.07 0-13.52 10.97-24.49 24.49-24.49 8.01 0 15.13 3.85 19.6 9.81zm.06 29.29 15.29 16.77c-8.6 9.57-21.07 15.59-34.95 15.59-10.78 0-20.72-3.64-28.65-9.75l.43.2 15.22-16.67c3.77 2.37 8.22 3.74 13 3.74 8.05 0 15.19-3.89 19.66-9.88z" />
      </clipPath>
      <clipPath id="id1">
        <path d="M27.34 67.9 10.45 56.84 0 71.52l28.15 12.87 44.63-48.86L27.34 67.9z" />
      </clipPath>
      <style>{".fil7{fill:none}.fil0{fill:#000;fill-rule:nonzero}"}</style>
    </defs>
    <g id="Camada_x0020_1">
      <g id="_2658991580880">
        <path
          d="M129.19 57.35c0 3.96-1.17 7.05-3.53 9.24-2.35 2.2-5.67 3.3-9.94 3.3-4.28 0-7.59-1.1-9.94-3.3-2.36-2.19-3.53-5.28-3.53-9.24v-34.2c0-3.96 1.17-7.04 3.53-9.24 2.35-2.2 5.66-3.3 9.94-3.3 4.27 0 7.59 1.1 9.94 3.3 2.36 2.2 3.53 5.28 3.53 9.24v5.11h-9.47v-4.74c0-1.36-.38-2.49-1.12-3.39-.74-.9-1.7-1.35-2.88-1.35s-2.14.45-2.88 1.35c-.74.9-1.12 2.03-1.12 3.39v33.46c0 1.36.38 2.49 1.12 3.39.74.9 1.7 1.35 2.88 1.35s2.14-.45 2.88-1.35c.74-.9 1.12-2.03 1.12-3.39v-4.74h9.47v5.11z"
          className="fil0"
        />
        <path
          id="_1"
          d="M155.24 60.97v8.18h-21.19v-57.8h21.19v8.18h-11.8v15.8h10.41v7.99h-10.5v17.65z"
          className="fil0"
        />
        <path
          id="_2"
          d="M185.38 55.86c0 4.52-1.2 7.99-3.58 10.41-2.39 2.42-5.78 3.62-10.18 3.62-4.33 0-7.76-1.11-10.26-3.34-2.51-2.23-3.77-5.3-3.77-9.2v-5.11h9.39v5.02c0 1.42.43 2.58 1.3 3.48.87.9 1.98 1.35 3.34 1.35 1.55 0 2.76-.53 3.63-1.58.87-1.06 1.3-2.54 1.3-4.46 0-3.1-.96-5.64-2.88-7.62L163.45 38.3c-1.8-1.8-3.1-3.76-3.91-5.9-.8-2.14-1.2-4.79-1.2-7.95 0-9.23 4.43-13.84 13.28-13.84 4.22 0 7.44 1.08 9.67 3.25 2.23 2.17 3.34 5.27 3.34 9.29v5.11h-8.82v-5.02c0-1.42-.39-2.58-1.17-3.48-.77-.9-1.78-1.35-3.02-1.35-1.36 0-2.43.58-3.2 1.72-.78 1.15-1.16 2.68-1.16 4.6 0 3.1.83 5.48 2.51 7.16l10.12 10.13c1.74 1.67 3.09 3.6 4.05 5.8.96 2.2 1.44 4.88 1.44 8.04z"
          className="fil0"
        />
        <path
          id="_3"
          d="M207.89 24.08c0-1.55-.37-2.74-1.11-3.58-.75-.83-1.8-1.25-3.16-1.25h-4.46v19.98h3.99c1.49 0 2.65-.44 3.49-1.3.83-.87 1.25-2.08 1.25-3.63V24.08zm9.3 11.43c0 3.84-1.09 6.78-3.26 8.83-2.17 2.04-5.23 3.07-9.2 3.07h-5.57v21.74h-9.39v-57.8h13.85c4.71 0 8.14.99 10.31 2.97 2.17 1.99 3.26 5.12 3.26 9.39v11.8z"
          className="fil0"
        />
        <path
          id="_4"
          d="M243.05 60.97v8.18h-21.19v-57.8h21.19v8.18h-11.8v15.8h10.4v7.99h-10.5v17.65z"
          className="fil0"
        />
        <path
          id="_5"
          d="M273.28 57.35c0 3.96-1.18 7.05-3.54 9.24-2.35 2.2-5.66 3.3-9.94 3.3-4.27 0-7.59-1.1-9.94-3.3-2.36-2.19-3.53-5.28-3.53-9.24v-34.2c0-3.96 1.17-7.04 3.53-9.24 2.35-2.2 5.67-3.3 9.94-3.3 4.28 0 7.59 1.1 9.94 3.3 2.36 2.2 3.54 5.28 3.54 9.24v5.11h-9.48v-4.74c0-1.36-.37-2.49-1.12-3.39-.74-.9-1.7-1.35-2.88-1.35s-2.14.45-2.88 1.35c-.74.9-1.11 2.03-1.11 3.39v33.46c0 1.36.37 2.49 1.11 3.39.74.9 1.7 1.35 2.88 1.35s2.14-.45 2.88-1.35c.75-.9 1.12-2.03 1.12-3.39v-4.74h9.48v5.11z"
          className="fil0"
        />
        <path
          d="m106.83 74.95-.3.78c-.29-.22-.75-.33-1.38-.33-.59 0-1.06.26-1.41.76-.36.51-.53 1.16-.53 1.95 0 .77.18 1.38.54 1.86.36.47.83.71 1.41.71.62 0 1.11-.22 1.44-.66l.51.69c-.53.53-1.22.79-2.05.79-.87 0-1.56-.32-2.06-.95-.51-.63-.76-1.46-.76-2.49 0-1 .27-1.83.81-2.49.53-.66 1.23-.99 2.08-.99.72 0 1.29.12 1.7.37z"
          className="fil0"
        />
        <path
          id="_1_0"
          d="M112.1 79.03h-3.53c0 .57.16 1.01.47 1.32.28.27.64.4 1.07.4.5 0 .91-.15 1.24-.44l.36.63c-.13.13-.33.25-.61.35-.35.13-.73.19-1.16.19-.62 0-1.14-.21-1.57-.63-.47-.46-.71-1.07-.71-1.85 0-.81.24-1.46.73-1.94.44-.44.96-.66 1.56-.66.7 0 1.24.2 1.64.59.38.38.57.88.57 1.51 0 .19-.02.37-.06.53zm-2.11-1.9c-.39 0-.71.13-.97.38-.25.24-.39.54-.43.89h2.71c0-.35-.11-.64-.33-.88-.24-.26-.56-.39-.98-.39z"
          className="fil0"
        />
        <path
          id="_2_1"
          d="M115.97 81.39v-2.85c0-.52-.08-.88-.23-1.09-.16-.21-.42-.32-.79-.32-.2 0-.41.06-.62.18-.22.12-.39.27-.5.44v3.64h-.87V76.5h.59l.28.63c.28-.48.75-.73 1.4-.73 1.07 0 1.61.66 1.61 1.96v3.03h-.87z"
          className="fil0"
        />
        <path
          id="_3_2"
          d="M118.26 77.18h-.57v-.68h.57v-1.02l.87-.34v1.36h1.35v.68h-1.35v2.43c0 .41.07.71.21.88.14.17.36.26.67.26.23 0 .46-.05.7-.17l.12.76c-.36.1-.75.14-1.18.14-.39 0-.72-.14-.99-.43-.26-.29-.4-.65-.4-1.09v-2.78z"
          className="fil0"
        />
        <path
          id="_4_3"
          d="M124.21 77.33c-.19-.13-.38-.2-.58-.2-.3 0-.57.14-.8.43-.23.28-.35.62-.35 1.02v2.81h-.87V76.5h.87v.77c.32-.58.79-.87 1.42-.87.15 0 .37.03.67.09l-.36.84z"
          className="fil0"
        />
        <path
          id="_5_4"
          d="M124.75 78.93c0-.75.2-1.37.61-1.83.41-.46.94-.7 1.6-.7.7 0 1.25.23 1.63.67.39.45.58 1.07.58 1.86s-.2 1.41-.59 1.87c-.4.45-.93.68-1.62.68-.69 0-1.24-.23-1.63-.69-.39-.46-.58-1.08-.58-1.86zm.92 0c0 1.23.43 1.84 1.29 1.84.41 0 .72-.16.95-.49.23-.33.35-.78.35-1.35 0-1.21-.43-1.82-1.3-1.82-.39 0-.71.16-.94.49-.24.32-.35.76-.35 1.33z"
          className="fil0"
        />
        <path
          id="_6"
          d="M135.89 81.39v-.36c-.3.29-.74.44-1.31.44-.61 0-1.11-.22-1.49-.66-.38-.43-.57-1.02-.57-1.75s.22-1.36.66-1.88c.43-.52.96-.78 1.56-.78.51 0 .89.12 1.15.36V74.5h.87v6.89h-.87zm0-3.76c-.22-.33-.52-.5-.9-.5-.46 0-.84.18-1.12.52-.29.35-.44.79-.44 1.33 0 1.17.54 1.76 1.61 1.76.14 0 .3-.04.49-.13.19-.08.31-.18.36-.27v-2.71z"
          className="fil0"
        />
        <path
          id="_7"
          d="M142 79.03h-3.52c0 .57.16 1.01.47 1.32.28.27.63.4 1.07.4.49 0 .91-.15 1.24-.44l.36.63c-.13.13-.34.25-.61.35-.35.13-.74.19-1.16.19-.62 0-1.14-.21-1.57-.63-.48-.46-.71-1.07-.71-1.85 0-.81.24-1.46.73-1.94.44-.44.96-.66 1.56-.66.7 0 1.24.2 1.64.59.38.38.57.88.57 1.51 0 .19-.02.37-.07.53zm-2.1-1.9c-.39 0-.71.13-.97.38-.25.24-.39.54-.43.89h2.71c0-.35-.11-.64-.33-.88-.24-.26-.56-.39-.98-.39z"
          className="fil0"
        />
        <path
          id="_8"
          d="m145.45 81.08.34-.84c.17.12.38.23.64.31.25.08.48.13.68.13.36 0 .64-.1.86-.3.22-.19.33-.44.33-.74 0-.23-.07-.43-.19-.63-.12-.19-.42-.4-.9-.63l-.54-.25c-.46-.21-.78-.47-.96-.76-.19-.29-.28-.64-.28-1.05 0-.5.18-.92.54-1.25.35-.33.8-.49 1.36-.49.74 0 1.26.12 1.54.36l-.26.8c-.13-.09-.31-.18-.56-.26-.25-.08-.48-.12-.7-.12-.31 0-.55.09-.73.26-.18.18-.27.4-.27.68 0 .17.03.33.1.47.06.14.15.26.26.35.12.09.35.23.7.4l.55.26c.46.21.78.47.97.77.18.3.27.68.27 1.14 0 .51-.2.93-.6 1.28-.41.35-.95.53-1.62.53-.6 0-1.11-.14-1.53-.42z"
          className="fil0"
        />
        <path
          id="_9"
          d="M154.19 79.03h-3.52c0 .57.15 1.01.47 1.32.27.27.63.4 1.06.4.5 0 .91-.15 1.25-.44l.36.63c-.13.13-.34.25-.62.35-.34.13-.73.19-1.15.19-.62 0-1.14-.21-1.57-.63-.48-.46-.72-1.07-.72-1.85 0-.81.25-1.46.74-1.94.44-.44.95-.66 1.55-.66.7 0 1.25.2 1.64.59.39.38.58.88.58 1.51 0 .19-.02.37-.07.53zm-2.1-1.9c-.39 0-.72.13-.98.38-.25.24-.39.54-.42.89h2.71c0-.35-.11-.64-.33-.88-.24-.26-.57-.39-.98-.39z"
          className="fil0"
        />
        <path
          id="_10"
          d="M155.12 80.01V74.5h.87v5.36c0 .26.07.47.22.62.16.15.35.22.59.22v.78c-1.12 0-1.68-.49-1.68-1.47z"
          className="fil0"
        />
        <path
          id="_11"
          d="M161.82 79.03h-3.52c0 .57.15 1.01.47 1.32.27.27.63.4 1.06.4.5 0 .91-.15 1.24-.44l.37.63c-.13.13-.34.25-.62.35-.34.13-.73.19-1.16.19-.61 0-1.13-.21-1.56-.63-.48-.46-.72-1.07-.72-1.85 0-.81.25-1.46.74-1.94.44-.44.95-.66 1.55-.66.7 0 1.25.2 1.64.59.39.38.58.88.58 1.51 0 .19-.03.37-.07.53zm-2.1-1.9c-.39 0-.72.13-.98.38-.25.24-.39.54-.42.89h2.71c0-.35-.11-.64-.33-.88-.24-.26-.57-.39-.98-.39z"
          className="fil0"
        />
        <path
          id="_12"
          d="m166.41 76.89-.44.61c-.09-.09-.24-.17-.47-.25-.23-.08-.45-.12-.66-.12-.48 0-.86.17-1.14.5-.28.33-.42.78-.42 1.36 0 .57.14 1.01.43 1.31.28.3.67.45 1.17.45.39 0 .79-.15 1.18-.45l.35.73c-.47.3-1.05.45-1.74.45-.66 0-1.21-.22-1.65-.67-.44-.45-.65-1.06-.65-1.82 0-.78.22-1.4.68-1.88.46-.47 1.08-.71 1.87-.71.25 0 .53.06.83.16.3.11.51.22.66.33zm-2.49 6.57-.11-.45c.23 0 .43-.04.59-.12.16-.08.24-.2.24-.34 0-.23-.14-.34-.41-.34v-.54c.3 0 .53.08.7.25.17.17.25.38.25.63 0 .28-.11.51-.33.67-.22.16-.54.24-.93.24z"
          className="fil0"
        />
        <path
          id="_13"
          d="M169.96 80.83c-.35.43-.88.65-1.59.65-.39 0-.72-.14-1-.42-.28-.28-.42-.62-.42-1.04 0-.5.21-.92.65-1.26.43-.34.98-.51 1.65-.51.19 0 .39.04.62.12 0-.79-.35-1.19-1.05-1.19-.55 0-.96.15-1.26.44l-.36-.73c.17-.13.39-.25.68-.34.29-.1.57-.15.84-.15.71 0 1.22.17 1.54.49.32.32.48.83.48 1.54v1.75c0 .43.13.72.39.86v.43c-.36 0-.62-.05-.8-.15-.17-.1-.3-.26-.37-.49zm-.09-1.85c-.27-.06-.46-.09-.57-.09-.44 0-.79.11-1.07.34-.27.23-.41.49-.41.8 0 .51.3.77.89.77.44 0 .83-.21 1.16-.63v-1.19zm.51-4.4c-.16.63-.45.94-.88.94-.19 0-.4-.05-.63-.17-.23-.12-.39-.18-.49-.18-.21 0-.38.12-.49.35h-.41c.04-.25.14-.47.3-.66.15-.19.33-.28.54-.28.25 0 .48.06.69.18.21.12.37.18.47.18.25 0 .41-.12.5-.36h.4z"
          className="fil0"
        />
        <path
          id="_14"
          d="M171.68 78.93c0-.75.2-1.37.61-1.83.41-.46.94-.7 1.6-.7.7 0 1.25.23 1.63.67.39.45.58 1.07.58 1.86s-.2 1.41-.59 1.87c-.4.45-.93.68-1.62.68-.69 0-1.24-.23-1.63-.69-.39-.46-.58-1.08-.58-1.86zm.92 0c0 1.23.43 1.84 1.29 1.84.41 0 .72-.16.95-.49.23-.33.35-.78.35-1.35 0-1.21-.43-1.82-1.3-1.82-.39 0-.71.16-.94.49-.24.32-.35.76-.35 1.33z"
          className="fil0"
        />
        <path
          id="_15"
          d="m177.3 83.04-.23-.33c.52-.43.79-.79.79-1.09 0-.13-.05-.27-.14-.4-.26-.13-.4-.3-.4-.54 0-.16.07-.29.19-.4.13-.1.28-.15.47-.15.17 0 .32.07.45.21.13.15.2.32.2.51 0 .41-.08.77-.25 1.1-.17.32-.53.69-1.08 1.09z"
          className="fil0"
        />
        <path
          id="_16"
          d="M184.02 78.82v2.57h-.92V74.7c.7-.03 1.11-.04 1.26-.04 1.94 0 2.91.65 2.91 1.95 0 1.5-.85 2.25-2.57 2.25-.11 0-.33-.01-.68-.04zm0-3.3v2.47c.39.03.59.05.61.05 1.13 0 1.7-.45 1.7-1.34 0-.81-.61-1.22-1.81-1.22-.12 0-.29.01-.5.04z"
          className="fil0"
        />
        <path
          id="_17"
          d="M191.82 79.03h-3.52c0 .57.15 1.01.47 1.32.27.27.63.4 1.06.4.5 0 .91-.15 1.24-.44l.37.63c-.13.13-.34.25-.62.35-.34.13-.73.19-1.16.19-.61 0-1.13-.21-1.56-.63-.48-.46-.72-1.07-.72-1.85 0-.81.25-1.46.74-1.94.43-.44.95-.66 1.55-.66.7 0 1.25.2 1.64.59.39.38.58.88.58 1.51 0 .19-.03.37-.07.53zm-2.1-1.9c-.39 0-.72.13-.98.38-.25.24-.39.54-.42.89h2.71c0-.35-.11-.64-.33-.88-.24-.26-.57-.39-.98-.39z"
          className="fil0"
        />
        <path
          id="_18"
          d="m192.37 81.1.3-.83c.48.32.87.48 1.17.48.53 0 .8-.22.8-.67 0-.33-.26-.61-.78-.84-.4-.18-.66-.32-.8-.41-.14-.1-.26-.21-.36-.33a1.248 1.248 0 0 1-.31-.82c0-.4.15-.71.44-.94.3-.22.68-.34 1.15-.34.36 0 .81.12 1.35.34l-.25.8c-.34-.27-.69-.41-1.03-.41-.21 0-.39.05-.53.15-.14.1-.21.22-.21.37 0 .31.18.55.53.71l.63.29c.37.17.65.37.82.59.18.22.26.5.26.84 0 .44-.15.78-.46 1.03-.3.25-.73.37-1.27.37-.52 0-1-.13-1.45-.38z"
          className="fil0"
        />
        <path
          id="_19"
          d="M199.49 83.3v-2.2c-.25.25-.68.38-1.27.38-.67 0-1.19-.22-1.57-.67-.38-.44-.57-1.06-.57-1.86 0-.78.22-1.4.66-1.86.43-.46.99-.69 1.67-.69.42 0 .82.16 1.19.47l.23-.37h.53v6.8h-.87zm0-5.79c-.25-.25-.56-.38-.94-.38-.48 0-.87.17-1.14.5-.27.33-.41.77-.41 1.32 0 .57.14 1.01.41 1.33.27.31.64.47 1.11.47.41 0 .74-.11.97-.32v-2.92z"
          className="fil0"
        />
        <path
          id="_20"
          d="M202.27 76.5v3.12c0 .75.33 1.13.98 1.13.29 0 .55-.08.79-.24.24-.17.39-.36.47-.58V76.5h.87v4.89h-.87v-.68c-.1.19-.29.37-.59.53-.29.16-.58.24-.86.24-.53 0-.94-.15-1.23-.46-.28-.31-.43-.75-.43-1.31V76.5h.87z"
          className="fil0"
        />
        <path
          id="_21"
          d="M207.34 74.65c.14 0 .27.05.38.15a.529.529 0 0 1 0 .76c-.11.11-.24.16-.38.16-.15 0-.28-.05-.38-.16a.492.492 0 0 1-.16-.38c0-.15.05-.28.16-.38.1-.1.23-.15.38-.15zm-.48 6.74v-4.16h-.68v-.73h1.55v4.89h-.87z"
          className="fil0"
        />
        <path
          id="_22"
          d="m208.68 81.1.31-.83c.48.32.87.48 1.16.48.54 0 .8-.22.8-.67 0-.33-.26-.61-.77-.84-.4-.18-.67-.32-.81-.41-.14-.1-.26-.21-.36-.33-.1-.12-.18-.24-.23-.38a1.27 1.27 0 0 1-.07-.44c0-.4.14-.71.44-.94.29-.22.67-.34 1.14-.34.36 0 .81.12 1.35.34l-.24.8c-.35-.27-.69-.41-1.04-.41-.21 0-.38.05-.52.15-.15.1-.22.22-.22.37 0 .31.18.55.54.71l.62.29c.38.17.66.37.83.59.17.22.26.5.26.84 0 .44-.16.78-.46 1.03-.31.25-.74.37-1.28.37-.51 0-1-.13-1.45-.38z"
          className="fil0"
        />
        <path
          id="_23"
          d="M215.43 80.83c-.35.43-.88.65-1.6.65-.38 0-.71-.14-.99-.42-.28-.28-.42-.62-.42-1.04 0-.5.21-.92.65-1.26.43-.34.98-.51 1.65-.51.19 0 .39.04.62.12 0-.79-.35-1.19-1.05-1.19-.55 0-.96.15-1.26.44l-.36-.73c.16-.13.39-.25.68-.34.29-.1.57-.15.84-.15.7 0 1.22.17 1.54.49.32.32.48.83.48 1.54v1.75c0 .43.13.72.39.86v.43c-.36 0-.62-.05-.8-.15-.17-.1-.3-.26-.37-.49zm-.09-1.85c-.27-.06-.46-.09-.57-.09-.44 0-.79.11-1.07.34-.28.23-.41.49-.41.8 0 .51.3.77.89.77.44 0 .83-.21 1.16-.63v-1.19z"
          className="fil0"
        />
        <path
          id="_24"
          d="M224.4 79.03h-3.52c0 .57.16 1.01.47 1.32.28.27.63.4 1.07.4.49 0 .91-.15 1.24-.44l.36.63c-.13.13-.34.25-.61.35-.35.13-.74.19-1.16.19-.62 0-1.14-.21-1.57-.63-.48-.46-.71-1.07-.71-1.85 0-.81.24-1.46.73-1.94.44-.44.96-.66 1.56-.66.7 0 1.24.2 1.64.59.38.38.57.88.57 1.51 0 .19-.02.37-.07.53zm-2.1-1.9c-.39 0-.71.13-.97.38-.25.24-.39.54-.43.89h2.71c0-.35-.11-.64-.33-.88-.24-.26-.56-.39-.98-.39z"
          className="fil0"
        />
        <path
          id="_25"
          d="m232.43 74.95-.31.78c-.29-.22-.75-.33-1.38-.33-.59 0-1.06.26-1.41.76-.36.51-.53 1.16-.53 1.95 0 .77.18 1.38.54 1.86.37.47.83.71 1.41.71.62 0 1.11-.22 1.44-.66l.51.69c-.53.53-1.21.79-2.05.79-.87 0-1.56-.32-2.06-.95-.5-.63-.76-1.46-.76-2.49 0-1 .27-1.83.81-2.49.53-.66 1.23-.99 2.08-.99.72 0 1.29.12 1.71.37z"
          className="fil0"
        />
        <path
          id="_26"
          d="M233.25 78.93c0-.75.2-1.37.61-1.83.4-.46.94-.7 1.6-.7.7 0 1.24.23 1.63.67.39.45.58 1.07.58 1.86s-.2 1.41-.59 1.87c-.4.45-.94.68-1.62.68-.7 0-1.24-.23-1.63-.69-.39-.46-.58-1.08-.58-1.86zm.91 0c0 1.23.44 1.84 1.3 1.84.4 0 .72-.16.95-.49.23-.33.34-.78.34-1.35 0-1.21-.43-1.82-1.29-1.82-.39 0-.71.16-.94.49-.24.32-.36.76-.36 1.33z"
          className="fil0"
        />
        <path
          id="_27"
          d="M241.48 81.39v-2.85c0-.52-.08-.88-.23-1.09-.16-.21-.42-.32-.79-.32-.2 0-.41.06-.62.18-.22.12-.39.27-.5.44v3.64h-.87V76.5h.59l.28.63c.28-.48.75-.73 1.4-.73 1.07 0 1.61.66 1.61 1.96v3.03h-.87z"
          className="fil0"
        />
        <path
          id="_28"
          d="m243.15 81.1.3-.83c.48.32.87.48 1.17.48.53 0 .8-.22.8-.67 0-.33-.26-.61-.78-.84-.4-.18-.66-.32-.8-.41-.14-.1-.26-.21-.36-.33a1.248 1.248 0 0 1-.31-.82c0-.4.15-.71.44-.94.3-.22.68-.34 1.15-.34.36 0 .81.12 1.35.34l-.25.8c-.34-.27-.69-.41-1.03-.41-.21 0-.39.05-.53.15-.14.1-.21.22-.21.37 0 .31.18.55.53.71l.63.29c.37.17.65.37.82.59.18.22.26.5.26.84 0 .44-.15.78-.46 1.03-.3.25-.73.37-1.27.37-.52 0-1-.13-1.45-.38z"
          className="fil0"
        />
        <path
          id="_29"
          d="M247.96 76.5v3.12c0 .75.33 1.13.98 1.13.28 0 .54-.08.78-.24.24-.17.4-.36.47-.58V76.5h.87v4.89h-.87v-.68c-.09.19-.29.37-.58.53-.3.16-.58.24-.87.24-.53 0-.94-.15-1.22-.46-.29-.31-.43-.75-.43-1.31V76.5h.87z"
          className="fil0"
        />
        <path
          id="_30"
          d="M252.19 80.01V74.5h.87v5.36c0 .26.08.47.23.62.15.15.35.22.59.22v.78c-1.12 0-1.69-.49-1.69-1.47z"
          className="fil0"
        />
        <path
          id="_31"
          d="M255.08 77.18h-.57v-.68h.57v-1.02l.87-.34v1.36h1.35v.68h-1.35v2.43c0 .41.07.71.21.88.14.17.36.26.67.26.23 0 .46-.05.7-.17l.12.76c-.36.1-.75.14-1.18.14-.39 0-.72-.14-.99-.43-.26-.29-.4-.65-.4-1.09v-2.78z"
          className="fil0"
        />
        <path
          id="_32"
          d="M258.05 78.93c0-.75.2-1.37.61-1.83.41-.46.94-.7 1.6-.7.7 0 1.25.23 1.63.67.39.45.58 1.07.58 1.86s-.2 1.41-.59 1.87c-.4.45-.94.68-1.62.68-.69 0-1.24-.23-1.63-.69-.39-.46-.58-1.08-.58-1.86zm.92 0c0 1.23.43 1.84 1.29 1.84.41 0 .72-.16.95-.49.23-.33.35-.78.35-1.35 0-1.21-.43-1.82-1.3-1.82-.39 0-.71.16-.94.49-.24.32-.35.76-.35 1.33z"
          className="fil0"
        />
        <path
          id="_33"
          d="M265.93 77.33c-.19-.13-.38-.2-.57-.2-.31 0-.58.14-.81.43-.23.28-.34.62-.34 1.02v2.81h-.87V76.5h.87v.77c.31-.58.78-.87 1.41-.87.15 0 .38.03.67.09l-.36.84z"
          className="fil0"
        />
        <path
          id="_34"
          d="M267.68 74.65c.15 0 .28.05.38.15.11.11.16.24.16.38 0 .15-.05.28-.16.38-.1.11-.23.16-.38.16-.14 0-.27-.05-.38-.16a.52.52 0 0 1-.15-.38c0-.15.05-.28.15-.38.11-.1.23-.15.38-.15zm-.48 6.74v-4.16h-.67v-.73h1.54v4.89h-.87z"
          className="fil0"
        />
        <path
          id="_35"
          d="M272.1 80.83c-.35.43-.88.65-1.59.65-.38 0-.72-.14-1-.42-.28-.28-.42-.62-.42-1.04 0-.5.22-.92.65-1.26.43-.34.99-.51 1.66-.51.18 0 .39.04.62.12 0-.79-.35-1.19-1.06-1.19-.54 0-.96.15-1.25.44l-.37-.73c.17-.13.4-.25.69-.34.29-.1.56-.15.83-.15.71 0 1.22.17 1.55.49.32.32.48.83.48 1.54v1.75c0 .43.12.72.38.86v.43c-.35 0-.62-.05-.79-.15-.18-.1-.3-.26-.38-.49zm-.08-1.85c-.28-.06-.47-.09-.58-.09-.43 0-.79.11-1.06.34-.28.23-.42.49-.42.8 0 .51.3.77.9.77.43 0 .82-.21 1.16-.63v-1.19z"
          className="fil0"
        />
        <path
          d="m75.97 32.29 15.47-16.56C82.84 6.08 70.32 0 56.37 0 30.43 0 9.4 21.03 9.4 46.97c0 .67.01 1.33.04 1.99l17.83 11.63 6.35-4.55c-1.12-2.8-1.74-5.86-1.74-9.07 0-13.52 10.97-24.49 24.49-24.49 8.01 0 15.13 3.85 19.6 9.81zm.06 29.29 15.29 16.77c-8.6 9.57-21.07 15.59-34.95 15.59-10.78 0-20.72-3.64-28.65-9.75l.43.2 15.22-16.67c3.77 2.37 8.22 3.74 13 3.74 8.05 0 15.19-3.89 19.66-9.88z"
          style={{
            fill: "url(#id4)",
          }}
        />
        <g
          style={{
            clipPath: "url(#id0)",
          }}
        >
          <path
            id="_1_5"
            d="M62.85 12.72C45.99 15.06 37.05 20 27.99 37.49c-2.89 8.79-8.65 11.47-13.16 9.5-20.62-12.6 16.71-48.1 39.52-49.92 21.74-1 38.33 11.82 8.5 15.65z"
            style={{
              fill: "url(#id6)",
              fillOpacity: 0.501961,
            }}
          />
          <path
            d="M50.66 7.44c-5.37 1.44-10.81 3.49-16.53 8.46-4.27 3.49-7.1 7.45-9.26 11.64-2.31 4.99-5.02 6.94-8.19 5.55C2.17 24.23 32.36 1.45 48.41.17c10.81-2.43 11.01 5.46 2.25 7.27z"
            style={{
              fill: "url(#id5)",
              fillOpacity: 0.501961,
            }}
          />
          <path
            d="M102.31 67.81c-7.74 4.03-21.05 5.77-32.95 6.08-14.03 2.02-29.48-7.16-32.61 3.82-7.4 25.64 51.72 20.02 71.66 4.49 15.1-8.65 5.96-21.57-6.1-14.39z"
            style={{
              fill: "#07357a",
              fillOpacity: 0.501961,
            }}
          />
          <path
            d="M94.38 60.65c-5.14 4.98-15.19 9.46-24.47 12.49-10.55 4.87-24.84 1.28-24.72 10.64.19 21.88 45.32 3.61 57.35-13.26 9.83-10.33-.37-18.34-8.16-9.87z"
            style={{
              fill: "#0334c1",
              fillOpacity: 0.501961,
            }}
          />
          <path
            d="M93.01 60.6c-3.02 4.66-9.85 9.8-16.35 13.71-7.15 5.52-18.59 5.28-16.88 12.34 3.95 16.54 34.95-5.13 41.13-19.99 5.65-9.53-3.46-13.83-7.9-6.06z"
            style={{
              fill: "#2583ff",
              fillOpacity: 0.501961,
            }}
          />
        </g>
        <path
          d="m75.97 32.29 15.47-16.56C82.84 6.08 70.32 0 56.37 0 30.43 0 9.4 21.03 9.4 46.97c0 .67.01 1.33.04 1.99l17.83 11.63 6.35-4.55c-1.12-2.8-1.74-5.86-1.74-9.07 0-13.52 10.97-24.49 24.49-24.49 8.01 0 15.13 3.85 19.6 9.81zm.06 29.29 15.29 16.77c-8.6 9.57-21.07 15.59-34.95 15.59-10.78 0-20.72-3.64-28.65-9.75l.43.2 15.22-16.67c3.77 2.37 8.22 3.74 13 3.74 8.05 0 15.19-3.89 19.66-9.88z"
          className="fil7"
        />
        <path
          d="M27.34 67.9 10.45 56.84 0 71.52l28.15 12.87 44.63-48.86z"
          style={{
            fill: "url(#id2)",
          }}
        />
        <g
          style={{
            clipPath: "url(#id1)",
          }}
        >
          <path
            id="_1_6"
            d="m3.02 57.65-.81 5.22 26.94 13.27 46.44-43.02z"
            style={{
              fill: "url(#id3)",
            }}
          />
        </g>
        <path
          d="M27.34 67.9 10.45 56.84 0 71.52l28.15 12.87 44.63-48.86z"
          className="fil7"
        />
      </g>
    </g>
  </svg>
);
export default CespecSVG;
