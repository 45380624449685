import { Table } from "react-bootstrap";
import Titulo from "../../../components/Titulo";
import { useEffect, useState } from "react";
import Auth from "../../../helpers/Auth";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

const Importacao = () => {
  const [importacao, setImportacao] = useState({});
  const [loading, setLoading] = useState(true);
  const { http, logout } = Auth();

  function downloadFile(nome, data) {
    const blob = new Blob([data], { type: "application/RET" });
    saveAs(blob, `${nome}.RET`);
  }

  async function handleBaixarRet(nome) {
    setLoading(true);
    await http
      .post("baixar-arquivo", { nome })
      .then((res) => downloadFile(nome.split(".")[0], res.data))
      .catch((error) => {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await http
        .get("dados-ret")
        .then((res) => setImportacao(res.data))
        .catch((error) => {
          if (error.response.status === 401) logout();
          else {
            Swal.fire({
              icon: "error",
              text: "Algum erro com o servidor aconteceu. Tente novamente!",
              confirmButtonColor: "#dc3545",
            });
          }
        })
        .finally(() => setLoading(false));
    })();
  }, []);

  if (loading) return <Loading />;

  console.log(importacao);

  return (
    <>
      <Titulo texto={"Importação"} />

      <Table responsive>
        <thead>
          <tr className="text-center">
            <th>Transações</th>
            <th>Montante</th>
            <th>Download</th>
            <th>Data da Importação</th>
          </tr>
        </thead>

        <tbody>
          {importacao.length > 0 ? (
            importacao.map((item) => {
              return (
                <tr className="text-center" key={item.ret}>
                  <td>{item.totalValor}</td>
                  <td>-</td>
                  <td>
                    <Link
                      className="text-decoration-none"
                      onClick={() => handleBaixarRet(item.arquivoRet)}
                    >
                      {item.arquivoRet.split(".")[0]}
                    </Link>
                  </td>
                  <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                </tr>
              );
            })
          ) : (
            <tr className="text-center">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Importacao;
