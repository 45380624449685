import { Col, Form, Row } from "react-bootstrap";

const Hora = ({ label, change, objeto }) => {
  const hundleChange = (e) => {
    const { name, value } = e.target;
    change({ ...objeto, [name]: value });
  };

  const minutos = Array.from(
    { length: 60 },
    (_, index) => (index < 10 ? "0" : "") + index
  );

  const horas = Array.from(
    { length: 24 },
    (_, index) => (index < 10 ? "0" : "") + index
  );

  return (
    <Form.Group
      as={Row}
      controlId={label}
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={4}>
        <Form.Select
          aria-label="hora"
          defaultValue={objeto.hora || "00"}
          onChange={hundleChange}
          name="hora"
        >
          {horas.map((hora) => (
            <option key={hora} value={hora}>
              {hora}
            </option>
          ))}
        </Form.Select>
      </Col>

      <Col sm={4}>
        <Form.Select
          aria-label="hora"
          defaultValue={objeto.minuto || "00"}
          onChange={hundleChange}
          name="minuto"
        >
          {minutos.map((min) => (
            <option key={min} value={min}>
              {min}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Form.Group>
  );
};

export default Hora;
