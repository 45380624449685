import { Table } from "react-bootstrap";
import Auth from "../../../helpers/Auth";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const TabelaEtapa = () => {
  const { id } = useParams();
  const { http, logout } = Auth();
  const [loading, setLoading] = useState(true);

  const [etapas, setEtapas] = useState({})

  useEffect(() => {
    (async() => {
        await http.get(`etapas/${id}`)
        .then((resp) => setEtapas(resp.data))
        .catch((error) => {
          if (error.response.status === 401) logout();
          else {
            Swal.fire({
              icon: "error",
              text: 'Algum erro com o servidor aconteceu. Tente novamente!',
              confirmButtonColor: "#dc3545",
            });
          }
        })
        setLoading(false)
    })()
  }, []);

  if (loading) return <Loading/>
  else return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Etapa</th>
          <th>Realização</th>
          <th>Correção</th>
          <th>Adicionais</th>
          <th>Cartões Resposta</th>
        </tr>
      </thead>
      <tbody>
      {etapas.length > 0 && etapas.map((etp,index) => {
          return (
            <tr key={index}>
              <td>{etp.nome}</td>
              <td>{(etp.realizacao).split('-').reverse().join('/')}</td>
              <td>{(etp.correcao).split('-').reverse().join('/')}</td>
              <td>{etp.valor}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
};

export default TabelaEtapa;
