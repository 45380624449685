import { Container, Form, Tab, Tabs } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import Pessoal from "./components/Pessoal";
import Cargos from "./components/Cargos";
import Botoes from "../../components/Botao/Botoes";
import { useContext, useEffect, useState } from "react";
import { ConcursoContext } from "../../context/ConcursoContext";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Isencao from "./components/isencao";
import Swal from "sweetalert2";

const EditarConcurso = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { setPessoal, setCargos, concurso, setId, setIsencoes } =
    useContext(ConcursoContext);
  const { http, logout } = Auth();
  const [loading, setLoading] = useState(true);

  const enviarConcursos = async (request) => {
    try {
      await http.put(`editar-concurso/${params.id}`, request);
      navigate("/concursos");
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: 'Algum erro com o servidor aconteceu. Tente novamente!',
          confirmButtonColor: "#dc3545",
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    enviarConcursos(concurso);
  };

  useEffect(() => {
    (async () => {
      try {
        await http.get(`concurso/${params.id}`).then((res) => {
          setId(parseInt(params.id));
          setPessoal(res.data.pessoal);
          setCargos(res.data.cargos);
          setIsencoes(res.data.isencoes);
          setLoading(false);
        });
      } catch (error) {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: 'Algum erro com o servidor aconteceu. Tente novamente!',
            confirmButtonColor: "#dc3545",
          });
        }
      }
    })();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Container>
      <Titulo texto={"Editar Concurso"} />
      <Form onSubmit={handleSubmit}>
        <Tabs
          defaultActiveKey="Pessoal"
          id="justify-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Pessoal" title="Informações">
            <Pessoal />
          </Tab>
          <Tab eventKey="Cargos" title="Cargos">
            <Cargos />
          </Tab>
          <Tab eventKey="isencao" title="Isenções">
            <Isencao />
          </Tab>
        </Tabs>
        <Botoes path={"/concursos"} />
      </Form>
    </Container>
  );
};

export default EditarConcurso;
