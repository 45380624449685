import { Container } from "react-bootstrap";
import Titulo from "../../components/Titulo";

const Inicio = () => {
    return ( 
        <Container>
            <Titulo texto={'Painel de Controle'}/>
        </Container>
     )
}
 
export default Inicio;