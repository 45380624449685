import { Col, Form, Row } from "react-bootstrap";

const Date = ({ label, change, objeto, tipo }) => {
  const hundleChange = (e) => {
    change({ ...objeto, [tipo]: e.target.value });
  };

  return (
    <Form.Group
      as={Row}
      controlId="validationCustom01"
      className="mb-4 row align-items-center justify-content-center"
    >
      <Form.Label column sm={2} className="fw-bold text-sm-end">
        {label}
      </Form.Label>

      <Col sm={8}>
        <Form.Control type="date" onChange={hundleChange} value={objeto[tipo]} required/>
      </Col>
    </Form.Group>
  );
};

export default Date;
