import { Container, Table } from "react-bootstrap";
import { Button, Col, Form, Stack } from "react-bootstrap";
import Titulo from "../../components/Titulo";
import Auth from "../../helpers/Auth";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Pagina from "../../components/Pagina";
import Swal from "sweetalert2";

const Concursos = () => {
  const [concursos, setConcursos] = useState({});
  const [loading, setLoading] = useState(true);
  const [filtro, setFiltro] = useState({ concurso: "", status: "" });
  const { http, logout } = Auth();
  const width = window.innerWidth;
  const [page, setPage] = useState(1);
  const [infos, setInfos] = useState({ pages: 1, total: 0 });
  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);

  const getConcursos = async (e = null, type = null) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      await http
        .get("lista-concursos", {
          params: {
            page,
            qtd: 10,
            concurso: type === "reset" ? "" : filtro.concurso,
            status: type === "reset" ? "" : filtro.status,
          },
        })
        .then((res) => {
          setConcursos(res.data.data);
          setInfos({
            pages: res.data.totalPaginas,
            total: res.data.totalItens,
          });
        });
    } catch (error) {
      if (error.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const alertConfirmarEmail = (id) => {
    Swal.fire({
      icon: "warning",
      text: `Deseja enviar os emails de confirmação?`,
      showCancelButton: true,
      confirmButtonColor: "#0d6efd",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      // iconColor: "#dc3545",
    }).then((resp) => {
      if (resp.isConfirmed) enviarEmailsConfirmacao(id);
    });
  };

  async function enviarEmailsConfirmacao(id) {
    try {
      setLoading(true);
      await http.post("email-cartao-confirmacao", { id });
      Swal.fire({
        icon: "success",
        text: `Emails enviados com sucesso!`,
        confirmButtonText: "Ok",
      });
    } catch (err) {
      if (err.response.status === 401) logout();
      else {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getConcursos();
  }, [page]);

  if (loading) return <Loading />;
  else
    return (
      <Container>
        <Titulo texto={"Concursos"} btn />

        {concursos?.length > 0 && (
          <Form onSubmit={(e) => getConcursos(e)}>
            <Stack
              direction={width <= 768 ? "vertical" : "horizontal"}
              gap={2}
              className="my-5"
            >
              <p className="fs-3 m-0">Filtros</p>
              <Col md={4} lg={3} className={width <= 768 ? "" : "ms-auto"}>
                <Form.Group controlId="formGroupEmail">
                  <Form.Control
                    name="concurso"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setFiltro({ ...filtro, [name]: value });
                    }}
                    type="text"
                    value={filtro.concurso}
                    placeholder="Digite o nome do concurso"
                  />
                </Form.Group>
              </Col>
              <Col md={4} lg={3}>
                <Form.Select
                  name="status"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFiltro({ ...filtro, [name]: value });
                  }}
                  defaultValue={filtro.status || "default"}
                >
                  <option disabled value={"default"}>
                    Selecionar um status
                  </option>
                  <option value="Aberto">Aberto</option>
                  <option value="Em andamento">Em andamento</option>
                  <option value="Encerrado">Encerrado</option>
                  <option value="Suspenso">Suspenso</option>
                </Form.Select>
              </Col>
              <Button type="submit">Filtrar</Button>
              <Button
                type="submit"
                variant="danger"
                onClick={(e) => {
                  setFiltro({ concurso: "", status: "" });
                  getConcursos(e, "reset");
                }}
              >
                Limpar
              </Button>
            </Stack>
          </Form>
        )}

        <span>
          <strong>Total: </strong>
          {infos.total}
        </span>

        <Table hover responsive>
          <thead>
            <tr className="text-center align-middle">
              <th>ID</th>
              <th className="text-start">Nome</th>
              {/* <th>Cartão de Confirmação</th> */}
              <th>Status</th>
              <th>Oculto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {concursos &&
              concursos.length > 0 &&
              concursos.map((concurso, index) => {
                return (
                  <tr key={index} className="text-center align-middle">
                    <td>{concurso.id}</td>
                    <td className="text-start">
                      <Link
                        to={`/concursos/inscricao/${concurso.id}`}
                        className="text-decoration-none"
                      >
                        {concurso.nome}
                      </Link>
                    </td>
                    {/* <td>
                      {" "}
                      <Link
                        className="text-decoration-none"
                        onClick={() => alertConfirmarEmail(concurso.id)}
                      >
                        Visualizar
                      </Link>
                    </td> */}
                    <td>{concurso.status}</td>
                    <td>{concurso.oculto === 0 ? "Não" : "Sim"}</td>
                    <td>
                      <Link
                        to={`/concursos/editar/${concurso.id}`}
                        className="text-decoration-none"
                      >
                        Editar
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {infos.pages > 1 && (
          <Pagina
            total={infos.pages}
            current={page}
            onChangePage={handleChangePage}
          />
        )}
      </Container>
    );
};

export default Concursos;
