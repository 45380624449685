import { Form } from "react-bootstrap";

export default function Input({ id, label=null, ...rest }) {
  return (
    <Form.Group className="mb-3 fw-bold" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...rest}/>
    </Form.Group>
  );
}
