import { Button, Col, Row, Stack, Table } from "react-bootstrap";
import Auth from "../../../helpers/Auth";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { ChevronDown, ChevronUp, ReceiptText, X } from "lucide-react";

export default function TabelaCandidato({ data, setData }) {
  const { concursos } = data;
  const { logout, http } = Auth();
  const [showDetalhes, setShowDetalhes] = React.useState(
    Array(concursos?.length).fill(false)
  );

  function handleShowDetalhes(index) {
    const current = [...showDetalhes];
    current[index] = !current[index];
    setShowDetalhes(current);
  }

  function handleDetalhes(item, index) {
    if (!!item?.arquivos_s3)
      return (
        <Button variant="ligth" onClick={() => handleShowDetalhes(index)}>
          {showDetalhes[index] ? <ChevronUp /> : <ChevronDown />}
        </Button>
      );
    return <span>-</span>;
  }

  // async function handleMostrarIsencao(inscricao, tipo) {
  //   const index = inscricao.arquivos_s3[tipo].length;
  //   const name = inscricao.arquivos_s3[tipo][index - 1];
  //   const submit = {
  //     inscricao: inscricao.inscricao,
  //     tipo_arquivo: tipo,
  //     nome_arquivo: name,
  //     idUser: inscricao.users_id,
  //   };
  //   const resp = await http.post(`baixar-arquivo-s3`, submit, {
  //     responseType: "blob",
  //   });
  //   const buffer = resp.data;
  //   const type = resp.headers["content-type"];
  //   saveAs(new Blob([buffer]), name, { type });
  // }

  async function handleMostrarIsencao(inscricao, tipo) {
    const index = inscricao.arquivos_s3[tipo].length;
    const name = inscricao.arquivos_s3[tipo][index - 1];
    const submit = {
      inscricao: inscricao.inscricao,
      tipo_arquivo: tipo,
      nome_arquivo: name,
      idUser: inscricao.users_id,
    };

    try {
      const resp = await http.post(`baixar-arquivo-s3`, submit, {
        responseType: "blob",
      });
      const buffer = resp.data;
      const type = resp.headers["content-type"];
      const blob = new Blob([buffer], { type });
      const url = URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  }

  function statusPagamento(inscricao) {
    if (inscricao.isencao === 1) {
      if (inscricao.isencao_status === "Indeferido") {
        if (inscricao.pagamento === 0)
          return <span className="text-danger">Pendente</span>;
        return <span className="text-success">Pago</span>;
      }
      return <span>-</span>;
    }
    if (inscricao.pagamento === 0)
      return <span className="text-danger">Pendente</span>;
    return <span className="text-success">Pago</span>;
  }

  function dataHora(value) {
    // 2023-11-10T15:05:31.000000Z
    const data = new Date(value);
    data.setHours(data.getHours() - 4);
    const dataFormatada = data
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("-");
    const horario = data.toISOString().slice(11, 16);
    return dataFormatada + " / " + horario;
  }

  function handleNecessidades(items) {
    if (typeof items === `string`) return items;
    if (items)
      return items.map((item, index) => {
        if (items.length === index + 1) return <span key={item}>{item}</span>;
        return <span key={item}>{item + ", "}</span>;
      });
  }

  function colunaCartaoConfirmacao(insc) {
    const { arquivos_s3 } = insc;
    if (arquivos_s3?.["cartao-confirmacao"])
      return (
        <Link
          className="text-decoration-none"
          onClick={() => handleMostrarIsencao(insc, "cartao-confirmacao")}
        >
          Visualizar
        </Link>
      );
    return <span>-</span>;
  }

  const [, forceUpdate] = useState(0);

  function abrirHTMLemNovaAba(htmlPDF) {
    const novaAba = window.open();
    novaAba.document.write(htmlPDF);
    novaAba.document.close();
  }

  const handleGerarPdf = async (inscricao) => {
    await http
      .post("boleto-inscrito", { inscricao })
      .then((resp) => abrirHTMLemNovaAba(resp.data))
      .catch((error) => {
        if (error.response.status === 401) logout();
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            text: error.response.data.Message,
            confirmButtonColor: "#dc3545",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      });
  };

  function limparBoletoGerado(inscricao, index) {
    Swal.fire({
      icon: "warning",
      text: `Deseja limpar o boleto gerado?`,
      showCancelButton: true,
      confirmButtonText: "Limpar",
      confirmButtonColor: "#0d6efd",
      iconColor: "#cf3a00",
      cancelButtonText: "Cancelar",
    }).then(async (resp) => {
      if (resp.isConfirmed) {
        await http.post("limpar-boleto", {}, { params: { inscricao } });
        const values = data;
        values.concursos[index] = {
          ...values.concursos[index],
          boleto_existe: 0,
        };
        setData(values);
        console.log(values);
        forceUpdate(Math.random());
      }
    });
  }

  async function handleBoletoDownload(inscricao) {
    try {
      const { data } = await http.post(
        `gerar-pagamento-candidato`,
        {},
        {
          params: {
            inscricao,
          },
        }
      );
      Swal.fire({
        icon: "success",
        text: data.message,
        confirmButtonText: "Ok",
        confirmButtonColor: "#0d6efd",
      });

      // const buffer = resp.data;
      // const type = resp.headers["content-type"];
      // const blob = new Blob([buffer], { type });
      // const url = URL.createObjectURL(blob);
      // window.open(url);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    } finally {
      forceUpdate(Math.random());
    }
  }

  function hundleGerarBoleto(row, index) {
    const { isencao, boleto_existe, inscricao, pagamento } = row;
    if (isencao === 1) return <span>-</span>;
    if (!boleto_existe)
      return (
        <div className="d-flex align-items-center gap-3 flex-column">
          <ReceiptText color="#ba1f39" />
          {!pagamento && (
            <span
              style={{ color: "#0d6efd", cursor: "pointer" }}
              onClick={() => handleBoletoDownload(inscricao)}
            >
              Gerar
            </span>
          )}
        </div>
      );
    return (
      <Stack gap={3}>
        <Link
          className="text-decoration-none"
          onClick={() => handleGerarPdf(inscricao)}
        >
          <ReceiptText color="#17c183" />
        </Link>
        {!pagamento && (
          <span
            style={{ color: "#0d6efd", cursor: "pointer" }}
            onClick={() => limparBoletoGerado(inscricao, index)}
          >
            Limpar
          </span>
        )}
      </Stack>
    );
  }

  return (
    <Table responsive hover bordered className="mb-5">
      <thead>
        <tr className="text-center align-middle">
          <th>Inscrição</th>
          <th>Concurso</th>
          <th>Cargo</th>
          <th>Data/Hora da Inscrição</th>
          <th>Status Pagamento</th>
          <th>
            <ReceiptText color="#000" />
          </th>
          <th>Tipo Isenção</th>
          <th>Status Isenção</th>
          <th>Cartão Confirmação</th>
          <th>Detalhes</th>
        </tr>
      </thead>
      <tbody>
        {concursos?.length > 0 &&
          concursos.map((item, index) => {
            console.log(item.arquivos_s3);
            return (
              <React.Fragment key={item.inscricao}>
                <tr className="text-center align-middle">
                  <td>{String(item.inscricao).padStart(5, "0")}</td>
                  <td>{item.concursoNome}</td>
                  <td>{item.cargoNome}</td>
                  <td>{dataHora(item.created_at)}</td>
                  <td>{statusPagamento(item)}</td>
                  <td>{hundleGerarBoleto(item, index)}</td>
                  <td>{item.tipo_isencao}</td>
                  <td>{item.isencao_status}</td>
                  <td>{colunaCartaoConfirmacao(item)}</td>
                  <td>{handleDetalhes(item, index)}</td>
                </tr>
                {showDetalhes[index] && item.arquivos_s3 && (
                  <tr>
                    <td></td>
                    <td colSpan="9">
                      <div className="p-2">
                        {item.arquivos_s3.arquivo_isencao && (
                          <Row>
                            <Col xs={4}>
                              <p className="m-0 mb-2">
                                <strong>Isenção:</strong> {item.tipo_isencao}
                              </p>
                            </Col>
                            <Col>
                              <Link
                                className="text-decoration-none"
                                onClick={() =>
                                  handleMostrarIsencao(item, "arquivo_isencao")
                                }
                              >
                                Baixar Documento da Isenção
                              </Link>
                            </Col>
                          </Row>
                        )}
                        {item.arquivos_s3.arquivo_deficiencia && (
                          <Row>
                            <Col xs={4}>
                              <p className="m-0 mb-2">
                                <strong>Deficiência:</strong>{" "}
                                {item.tipo_deficiencia}
                              </p>
                            </Col>
                            <Col>
                              <Link
                                className="text-decoration-none"
                                onClick={() =>
                                  handleMostrarIsencao(
                                    item,
                                    "arquivo_deficiencia"
                                  )
                                }
                              >
                                Baixar Documento da Deficiência
                              </Link>
                            </Col>
                          </Row>
                        )}
                        {item.especial === 1 && (
                          <Row>
                            <Col>
                              <strong>Necessidades:</strong>{" "}
                              {handleNecessidades(item.acessibilidades)}
                            </Col>
                          </Row>
                        )}
                        {/* recursos_classificacao_preliminar_existe : 0
                            recursos_deficiencia_existe : 0
                            recursos_gabarito_preliminar_existe : 0
                            recursos_isencao_existe : 1 */}
                        {/* {item.recursos_isencao_existe === 1 && ( */}
                        {
                          <Row>
                            <Col xs={4}>
                              <p className="m-0 mb-2">
                                <strong>Recursos:</strong>
                              </p>
                            </Col>
                            <Col>
                              {item.arquivos_s3.recursos_isencao && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_isencao"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Isenção
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3.recursos_deficiencia && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_deficiencia"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Condição de
                                    Deficiência Física
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3
                                .recursos_gabarito_preliminar && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_gabarito_preliminar"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Gabarito
                                    Preliminar
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3
                                .recursos_classificacao_preliminar && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_classificacao_preliminar"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso de Classificação
                                    Preliminar
                                  </Link>
                                </Row>
                              )}
                              {item.arquivos_s3.recursos_edital_preliminar && (
                                <Row>
                                  <Link
                                    className="text-decoration-none"
                                    onClick={() =>
                                      handleMostrarIsencao(
                                        item,
                                        "recursos_edital_preliminar"
                                      )
                                    }
                                  >
                                    Baixar Documento de Recurso Edital
                                    Preliminar de deferimento das incrições
                                  </Link>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        }
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
      </tbody>
    </Table>
  );
}
