import { useContext, useEffect } from "react";
import { ConcursoContext } from "../../../context/ConcursoContext";
import AddIsencoes from "./AddIsencoes";
import { Button } from "react-bootstrap";

const Isencao = () => {
  const { isencoes, hundleMaisCampoIsencao } = useContext(ConcursoContext);

  return (
    <>
      <div className="d-flex justify-content-end my-4">
        <Button
          variant="primary"
          className="fw-bold"
          onClick={hundleMaisCampoIsencao}
          style={{ height: "36px" }}
        >
          Adicionar
        </Button>
      </div>
      {isencoes.map((item, i) => {
        return <AddIsencoes item={item} key={i} index={i} />;
      })}
    </>
  );
};

export default Isencao;
